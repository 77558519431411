import store from '../store/index'

export default function confirm({
  title = '',
  content = '',
  btn = {
    text: '',
    color: ''
  },
  callback = () => { } }
) {
  store.commit('confirm/setConfirmData', {
    isOpen: true,
    title,
    content,
    btn,
    callback
  })
}