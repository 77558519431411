import { api } from '..';

export function mrRenewToken(payload) {
  return api('/v1/merchantCenter/session/renewToken', payload);
}

export function loginByPasswordOTP(payload) {
  return api('/v1/merchantCenter/session/loginByPasswordOTP', payload);
}

export function getCurrentUser() {
  return api('/v1/merchantCenter/session/getCurrentUser', 'no-data');
}
