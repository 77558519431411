import Vue from 'vue';

import Async from '@/components/Globals/Async.vue';
import DataTable from '@/components/Globals/DataTable.vue';
import SizeBox from '@/components/Globals/SizeBox.vue';
import TitleWithLine from '@/components/Globals/TitleWithLine.vue';
import CDatePicker from '@/components/Globals/CDatePicker.vue';
import CRangePicker from '@/components/Globals/CRangePicker.vue';
import CAutocomplete from '@/components/Globals/CAutocomplete.vue';
import FilterBox from '@/components/Globals/FilterBox.vue';
import ExportBtn from '@/components/Globals/ExportBtn.vue';
import RefreshBtn from '@/components/Globals/RefreshBtn.vue';
import FunctionFab from '@/components/Globals/FunctionFab.vue';

Vue.component('Async', Async);
Vue.component('DataTable', DataTable);
Vue.component('SizeBox', SizeBox);
Vue.component('TitleWithLine', TitleWithLine);
Vue.component('CDatePicker', CDatePicker);
Vue.component('CRangePicker', CRangePicker);
Vue.component('CAutocomplete', CAutocomplete);
Vue.component('FilterBox', FilterBox);
Vue.component('ExportBtn', ExportBtn);
Vue.component('RefreshBtn', RefreshBtn);
Vue.component('FunctionFab', FunctionFab);
