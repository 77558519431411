<template>
  <v-expansion-panels
    :value="matchOpenedItem"
    class="elevation-0"
    accordion
    multiple
    dense
    flat
  >
    <template v-for="(nav, idx) in navs">
      <v-expansion-panel
        v-if="!nav.dontExist"
        :key="`${nav.name} - ${idx}`"
        :readonly="!nav.expandable"
        :class="{ variant }"
        class="panel elevation-0"
      >
        <v-expansion-panel-header
          v-show="!nav.hidden"
          #default="{ open }"
          class="panel_header elevation-0 pa-0"
          hide-actions
        >
          <div v-if="open" class="pos-a fill-all Primary100 b-rad-full"></div>
          <v-list-item
            :to="nav.children && !nav.showPath ? '' : nav.path"
            :disabled="nav.disabled"
            :class="!nav.path && 'cursor-normal'"
            active-class="shaped_active"
            dense
            nav
          >
            <v-list-item-icon v-if="nav.icon">
              <v-icon>{{ nav.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="d-flex align-center full-width">
                {{ nav.text }}
                <v-spacer></v-spacer>
                <v-icon v-if="nav.expandable" :class="{ rotate180: open }">
                  mdi-chevron-down
                </v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-expansion-panel-header>

        <v-expansion-panel-content
          v-if="nav.children"
          class="panel_content elevation-0 pa-0"
          :class="{ transparent: !nav.expandable }"
        >
          <nav-items :navs="nav.children" :variant="variant" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </template>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavItems',

  props: {
    navs: {
      type: Array,
      default: () => []
    },

    variant: {
      type: Boolean,
      default: false
    },

    root: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      open: false,
      matchOpenedItem: []
    };
  },

  mounted() {
    this.calcOpenItem();
  },

  computed: {
    ...mapGetters({ getIncludedRoute: 'app/getIncludedRoute' }),
    includedRoute: {
      immediate: true,
      get() {
        return this.getIncludedRoute(this.$route.name).map(({ path }) => path);
      }
    }
  },

  watch: {
    includedRoute() {
      this.calcOpenItem();
    }
  },

  methods: {
    calcOpenItem() {
      setTimeout(() => {
        const displayArray = [];
        if (this.root) {
          this.navs.forEach((el, i) => {
            displayArray.push(i);
          });

          this.matchOpenedItem = displayArray;
          return;
        }

        this.navs.filter(({ path }, i) => {
          if (
            path === '/' ||
            path === '/mr' ||
            this.includedRoute.includes(path)
          ) {
            displayArray.push(i);
            return true;
          }
        });

        this.matchOpenedItem = displayArray;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.panel {
  background-color: #ffffff00 !important;

  ::v-deep {
    .v-expansion-panel-content__wrap {
      padding: 0 !important;
      padding-left: 16px !important;
    }
  }
  &.variant {
    ::v-deep {
      .v-expansion-panel-content__wrap {
        padding: 0 !important;
      }
    }
  }
}

.panel_header {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 38px !important;

  ::v-deep {
    .v-list-item .v-list-item__content {
      color: black;
    }
    .v-list-item--link:before {
      border-radius: 999px;
    }

    .v-expansion-panel-header__icon {
      position: absolute;
      height: 100%;
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }

    .rotate180 {
      transform: rotate(180deg);
    }
  }
}

::v-deep {
  .v-expansion-panel-header--active ~ .panel_content {
    background-color: $Surface500;
    border-radius: 999px;
  }
}

.nav_divider {
  position: absolute;
  width: 95%;
  bottom: 0;
  right: 0;
}

.v-list-item {
  height: 100% !important;
  min-height: 34px !important;
  .v-list-item__icon * {
    color: $Primary600 !important;
  }
}

.v-list-item--active {
  background-color: $Primary600 !important;
  border-radius: 999px;
  .v-list-item__content {
    color: $Surface !important;
  }
  .v-list-item__icon * {
    color: $Surface !important;
  }
}
</style>
