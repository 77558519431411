<template>
  <!-- eslint-disable  -->
  <div
    class="Surface500 b-rad-8 pa-4 pos-r"
    :class="{ 'push-down': $vuetify.breakpoint.smAndDown && !pageAlign }"
  >
    <v-card outlined class="Surface500 b-rad-8">
      <v-data-table
        outlined
        :headers="indexedFields"
        :items="indexedList"
        :options="options"
        :items-per-page="itemsPerPage"
        :server-items-length="total"
        :loading="loading"
        hide-default-footer
        fixed-header
        @update:options="(v) => $emit('update:options', v)"
      >
        <template #no-data>
          <span class="mr-3">😢</span>
          您還沒有任何資料
        </template>
        <template #no-result>好像找不到要的結果 🧐</template>
        <template
          v-for="{ v, f } in replaceKeys"
          v-slot:[`item.${v}`]="{ item }"
        >
          <template v-if="f">
            {{
              ($root.$options.filters[f] &&
                typeof $root.$options.filters[f] === 'function' &&
                $root.$options.filters[f](item[v])) ||
              ''
            }}
          </template>
          <slot v-else :name="`item.${v}`" v-bind="{ item }" />
        </template>
        <template
          v-for="(label, k, i) of labels"
          v-slot:[`item.${k}`]="{ item }"
        >
          <v-chip
            v-for="l in label"
            :key="`${k}-label-${l.text}-${i}`"
            v-show="k.split('.').reduce((p, c) => p[c], item) === l.value"
            v-bind="l.chip"
            :color="l.color"
            small
          >
            {{ l.text }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <template v-if="showPages">
      <v-bottom-sheet
        v-if="$vuetify.breakpoint.smAndDown && !pageAlign"
        :value="true"
        hide-overlay
        persistent
        no-click-animation
        app
      >
        <v-card class="d-flex align-center pa-4">
          <div style="width: auto" class="d-flex align-center">
            <span class="mr-4 text-no-wrap">共 {{ computedTotal }} 則</span>
            <v-select
              :items="pageItems"
              :value="itemsPerPage"
              hide-details
              outlined
              rounded
              dense
              @change="handlePageCountChange"
            />
          </div>
          <div v-if="pageCount > 1" class="d-flex ml-3">
            <v-btn fab x-small :disabled="page <= 1" @click="page--">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  fab
                  x-small
                  class="mx-1 text-subtitle-1 font-weight-bold"
                >
                  {{ page }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="i in pageCount" @click="page = i">
                  {{ i }}
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn fab x-small :disabled="page >= pageCount" @click="page++">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-bottom-sheet>
      <div v-else class="d-flex align-center mt-3">
        <span>共 {{ computedTotal }} 則</span>
        <div style="width: auto" class="ml-3">
          <v-select
            rounded
            :items="pageItems"
            :value="itemsPerPage"
            dense
            outlined
            hide-details
            @change="handlePageCountChange"
          />
        </div>
        <v-col v-if="pageCount > 1">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="9"
            class="Secondary900--text"
            prev-icon="mdi-arrow-left"
            next-icon="mdi-arrow-right"
            @input="handlePageChange"
            circle
          />
        </v-col>
      </div>
    </template>

    <v-overlay :value="loading"></v-overlay>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    total: {
      type: Number
    },
    list: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    replace: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Object,
      default: () => ({})
    },
    showPages: {
      type: Boolean,
      default: true
    },
    pageAlign: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20
      },
      picker: '',
      page: 1,
      itemsPerPage: 20,
      pageItems: [
        { text: '20 筆/頁', value: 20 },
        { text: '50 筆/頁', value: 50 },
        { text: '100 筆/頁', value: 100 },
        { text: '300 筆/頁', value: 300 },
        { text: '500 筆/頁', value: 500 }
      ]
    };
  },

  computed: {
    computedTotal() {
      return this.total || this.list.length;
    },

    pageCount() {
      return Math.ceil(this.computedTotal / this.itemsPerPage);
    },

    indexedFields() {
      return this.fields && this.fields.length
        ? [
            {
              text: '',
              sortable: false,
              width: '32px',
              value: 'index'
            },
            ...this.fields.map((el) => ({ ...el, sortable: !!el.sortable }))
          ]
        : [];
    },

    indexedList() {
      return this.list && this.list.length
        ? this.list.map((el, i) => ({
            index: i + 1,
            ...el
          }))
        : [];
    },
    replaceKeys() {
      return this.replace.map((el) => ({
        v: el.split('|')[0],
        f: el.split('|')[1]
      }));
    }
  },

  watch: {
    page() {
      this.handlePageChange();
    },
    itemsPerPage() {
      this.page = 1;
    }
  },

  created() {
    // this.getList();
  },

  methods: {
    handlePageChange() {
      this.listQuery.page = this.page;
      this.getList();
    },

    handlePageCountChange(pageCount) {
      this.itemsPerPage = pageCount;
      this.listQuery.limit = pageCount;
      this.listQuery.page = 1;
      this.getList();
    },

    getList() {
      const condition = {
        page: this.listQuery.page,
        limit: this.listQuery.limit
      };

      this.$emit('get-list', condition);
    }
  }
};
</script>

<style lang="scss" scoped>
.push-down {
  margin-bottom: 104px;
}

::v-deep {
  .v-data-table__wrapper {
    height: calc(68vh - var(--filter-padding));
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      height: 100%;
    }
  }

  .v-select__selections {
    input {
      width: 20px;
    }
  }

  .v-data-table__mobile-row {
    flex-wrap: wrap;
    min-height: 28px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    .v-input--switch {
      margin-top: 0 !important;
      .v-messages {
        display: none !important;
      }
    }
  }

  th > span,
  .v-data-table__mobile-row__header,
  .v-data-table__mobile-row__cell {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      white-space: pre-wrap;
      word-break: keep-all;
    }
  }

  tr:nth-child(even) {
    background-color: $Surface050;
  }

  .sortable {
    color: $primary !important;
  }

  // tr {
  //   & > td {
  //     max-width: 400px;
  //   }
  // }
}
</style>
