<template>
  <v-navigation-drawer
    :value="isOpen"
    :permanent="smAndUp && isOpen"
    :temporary="smOnly && isOpen"
    :overlay-opacity="0"
    class="shaped_drawer elevation-0"
    color="white"
    width="234"
    floating
    app
    @input="(v) => $store.commit('app/setIsDrawerOpen', v)"
  >
    <v-card v-if="!variant" class="pos-f w100p z-index-2" flat>
      <v-btn
        rounded
        color="Secondary900"
        small
        text
        fab
        @click="$store.commit('app/setIsDrawerOpen', false)"
      >
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
    </v-card>
    <SizeBox height="52" />

    <div class="w100p d-flex justify-end pr-8">
      <nav-items :navs="navs" :variant="variant" root></nav-items>
    </div>

    <SizeBox height="144" />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

import navItems from './navItems.vue';
import { routes } from '@/router/routes';
import { mrRoutes } from '@/router/mrRoutes';

export default {
  components: { navItems },
  computed: {
    ...mapGetters({ isOpen: 'app/getIsDrawerOpen' }),
    isMr() {
      return /vip/.test(this.$route.path.split('/')[1]);
    },
    navs() {
      return this.isMr ? mrRoutes : routes();
    },
    mdAndUp() {
      return this.$vuetify.breakpoint && this.$vuetify.breakpoint.mdAndUp;
    },
    smAndUp() {
      return this.$vuetify.breakpoint && this.$vuetify.breakpoint.smAndUp;
    },
    smAndDown() {
      return this.$vuetify.breakpoint && this.$vuetify.breakpoint.smAndDown;
    },
    smOnly() {
      return this.$vuetify.breakpoint && this.$vuetify.breakpoint.smOnly;
    },

    variant() {
      return !this.isOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
.shaped_drawer {
  border-radius: 0 0 40px 0;
}
</style>
