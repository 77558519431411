<template>
  <div>
    <div class="d-none d-sm-flex align-center">
      <div class="d-flex align-center flex-wrap mt-2">
        <v-chip
          close
          color="Secondary800"
          dark
          @click="isFilterOpen = true"
          @click:close="doClean"
          class="my-2 d-none d-sm-flex"
        >
          篩選
        </v-chip>

        <template v-for="option in snpashotOptions">
          <v-chip
            v-if="isOptionShow(option)"
            color="Secondary400"
            class="filtered_option mr-2 mb-2"
            :class="{ wrap: option.type === 'timeSet' }"
            :key="option.name"
            small
          >
            {{ option.label }}:
            <br v-if="option.type === 'timeSet'" />
            {{ parseOptionText(option) }}
          </v-chip>
        </template>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        rounded
        color="primary"
        depressed
        @click="isFilterOpen = true"
        :small="$vuetify.breakpoint.smAndDown"
        class="my-2 mr-4"
      >
        <v-icon :size="$vuetify.breakpoint.smAndDown ? 20 : 24">
          mdi-filter-outline
        </v-icon>
        <span class="d-none d-sm-flex">篩選器</span>
      </v-btn>
    </div>

    <v-bottom-sheet
      v-model="isFilterOpen"
      @transition-end="calcContentHeight"
      scrollable
      no-click-animation
      hide-overlay
      persistent
      app
    >
      <v-card ref="FILTER_CONTENT">
        <v-card-title>
          篩選器
          <v-divider vertical class="mx-5"></v-divider>
          <v-spacer></v-spacer>
          <v-divider vertical class="mx-5"></v-divider>
          <v-icon
            @click="
              $emit('close');
              isFilterOpen = false;
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="py-5">
          <v-form ref="FilterForm">
            <v-row>
              <v-col
                v-for="item in filterOptions"
                :key="`filter${item.name}`"
                cols="auto"
              >
                <v-select
                  rounded
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  @input="
                    (v) =>
                      $set(
                        item,
                        'text',
                        (item.items.find(({ value }) => value === v) || {}).text
                      )
                  "
                  v-bind="{
                    ...commonAttrs,
                    ...item
                  }"
                ></v-select>
                <c-autocomplete
                  rounded
                  v-if="item.type === 'autocomplete'"
                  v-model="item.value"
                  @update:text="(v) => $set(item, 'text', v)"
                  v-bind="{
                    ...commonAttrs,
                    ...item
                  }"
                ></c-autocomplete>
                <v-text-field
                  rounded
                  v-if="item.type === 'text'"
                  v-model.trim="item.value"
                  v-bind="{
                    ...commonAttrs,
                    ...item
                  }"
                ></v-text-field>
                <c-range-picker
                  rounded
                  v-if="item.type === 'range'"
                  v-model="item.value"
                  type="text"
                  v-bind="{
                    ...commonAttrs,
                    ...item
                  }"
                />
                <c-date-picker
                  ref="DATE_PICK"
                  v-if="item.type === 'timeSet'"
                  v-model="item.value"
                  v-bind="{
                    ...commonAttrs,
                    ...item
                  }"
                ></c-date-picker>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn rounded depressed @click="doClean">清除搜尋內容</v-btn>
          <v-btn
            rounded
            color="primary"
            depressed
            @click="doSearch"
            :loading="loading"
          >
            搜尋
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import moment from 'moment';
import clone from 'just-clone';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array, // select, autocomplete, text, timeSet, range
      default: () => []
    },
    open: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isFilterOpen: this.open || false,
      commonAttrs: {
        clearable: true,
        outlined: true,
        dense: true,
        hideDetails: true
      },
      filterOptions: clone(this.options),
      snpashotOptions: this.options.map((el) => clone(el))
    };
  },

  computed: {
    parseFilter() {
      const output = {};
      this.filterOptions.forEach(({ name, value }) => {
        if (value !== null) {
          if (/./g.test(name)) {
            name.split('.').reduce((p, c, i, arr) => {
              !p[c] && (p[c] = {});
              if (i + 1 === arr.length) p[c] = value;
              return p[c];
            }, output);
          } else {
            output[name] = value;
          }
        }
      });

      return output;
    }
  },

  watch: {
    open(v) {
      this.isFilterOpen = v;
    },
    isFilterOpen(v) {
      this.calcContentHeight(v);
      this.$emit('update:open', v);
    },
    options: {
      deep: true,
      handler(v) {
        this.filterOptions = clone(v);
      }
    }
  },

  mounted() {
    if (Object.entries(this.$route.query).length) {
      this.filterOptions.forEach((el) => {
        if (this.$route.query[el.query]) {
          // give name for showing
          this.$set(el, 'text', this.$route.query.name);
          this.$set(el, 'value', this.$route.query[el.query]);
        }
      });
      setTimeout(() => {
        this.doSearch();
        this.isFilterOpen = !this.$vuetify.breakpoint.smAndDown;
      });
    } else {
      this.isFilterOpen = !this.$vuetify.breakpoint.smAndDown;
    }
  },

  beforeDestroy() {
    let root = document.documentElement.style.setProperty(
      `--filter-padding`,
      `0px`
    );
  },

  methods: {
    calcContentHeight(v) {
      setTimeout(() => {
        const height = v ? this.$refs.FILTER_CONTENT.$el.clientHeight : 0;
        let root = document.documentElement.style.setProperty(
          `--filter-padding`,
          `${height}px`
        );
      });
    },
    isOptionShow(option) {
      return Object.keys(option).find((v) => v === 'text')
        ? !!option.text
        : !!option.value;
    },
    parseOptionText(option) {
      if (option.type === 'timeSet') {
        return Array.isArray(option.value)
          ? option.value
              .map((t) => moment(t).format('YYYY-MM-DD H:mm:ss'))
              .join('\n')
          : moment(option.value).format('YYYY-MM-DD H:mm:ss');
      }
      return Object.keys(option).find((v) => v === 'text')
        ? option.text
        : option.value;
    },
    cleanQuery() {
      if (Object.entries(this.$route.query).length) {
        if (this.$route.query.mobile) {
          this.$router.replace({
            path: this.$route.path,
            query: { mobile: 1 }
          });
        } else {
          this.$router.replace({ path: this.$route.path, query: null });
        }
      }
    },
    doSearch() {
      this.$emit('input', this.parseFilter);
      this.snpashotOptions = this.filterOptions.map((el) => clone(el));
      this.cleanQuery();

      this.$nextTick(() => {
        this.$emit('search');
      });
    },
    doClean() {
      this.$refs.FilterForm && this.$refs.FilterForm.reset();
      this.$set(this, 'snpashotOptions', []);
      this.$set(this, 'filterOptions', clone(this.options));
      if (Array.isArray(this.$refs.DATE_PICK) && this.$refs.DATE_PICK.length) {
        this.$refs.DATE_PICK.forEach((el) => {
          el.$reset();
        });
      }

      this.$nextTick(() => {
        this.doSearch();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.filtered_option::v-deep {
  height: auto !important;
  &.wrap .v-chip__content {
    white-space: pre-wrap;
  }
}
</style>