import { api } from '.';

export function getCurrentUser(headers) {
  return api('/v1/session/getCurrentUser', {}, { headers });
}

export function loginByPasswordOTP(payload) {
  return api('/v1/session/loginByPasswordOTP', payload);
}

export function renewToken(payload) {
  return api('/v1/session/renewToken', payload);
}
