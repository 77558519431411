export default {
  namespaced: true,
  state: () => ({
    isOpen: false,
    title: '',
    content: '',
    needAuth: false,
    btn: {
      text: '',
      color: ''
    },
    callback: () => { }
  }),

  mutations: {
    setConfirmData(state, payload) {
      Object.keys(payload).forEach(key => state[key] = payload[key])
    },
  }
};
