import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { theme1, theme2 } from './color';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: /vip/.test(window.location.pathname.split('/')[1]) ? theme2 : theme1
  }
});
