import { api } from '.';

export function addMerchant(payload) {
  return api('/v1/merchants/addMerchant', payload);
}

export function removeMerchant(payload) {
  return api('/v1/merchants/removeMerchant', payload);
}

export function getMerchant(payload) {
  return api('/v1/merchants/getMerchant', payload);
}

export function getAgents(payload) {
  return api('/v1/merchants/getAgents', payload);
}

export function getMerchants(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/merchants/getMerchants', payload);
}

export function editMerchant(payload) {
  return api('/v1/merchants/editMerchant', payload);
}

export function resetMerchantApiSecret(merchant_id) {
  return api('/v1/merchants/resetMerchantApiSecret', { merchant_id });
}

export function resetMerchantApiSecretPw({ password, merchant_id }) {
  return api('/v1/merchants/resetMerchantApiSecretPw', {
    password,
    merchant_id
  });
}

export function checkSecrets({ password, merchant_id }) {
  return api('/v1/merchants/checkSecrets', { password, merchant_id });
}

export function checkCapital(id) {
  return api('/v1/merchants/checkCapital', { merchant_id: id });
}

export function addCapitalLog(payload) {
  return api('/v1/merchants/addCapitalLog', payload);
}

export function placeCollectOrder(payload) {
  return api('/v1/merchants/placeCollectOrder', payload);
}

export function getMerchantFeeSettings(payload) {
  return api('/v1/merchants/getMerchantFeeSettings', payload);
}

export function editMerchantFeeSettings(payload) {
  return api('/v1/merchants/editMerchantFeeSettings', payload);
}

export function editMerchantFeeSetting(payload) {
  return api('/v1/merchants/editMerchantFeeSetting', payload);
}

export function addCollectRule(payload) {
  return api('/v1/merchants/addCollectRule', payload);
}

export function editCollectRule(payload) {
  return api('/v1/merchants/editCollectRule', payload);
}

export function editPayRule(payload) {
  return api('/v1/merchants/editPayRule', payload);
}

export function getCollectChannelSettings(payload) {
  return api('/v1/merchants/getCollectChannelSettings', payload);
}

export function editCollectChannelSettings(payload) {
  return api('/v1/merchants/editCollectChannelSettings', payload);
}

export function getCollectChannels(payload) {
  return api('/v1/merchants/getCollectChannels', payload);
}

export function getAvailableCollectChannels(payload) {
  return api('/v1/merchants/getAvailableCollectChannels', payload);
}

export function editMerchantStatus(payload) {
  return api('/v1/merchants/editMerchantStatus', payload);
}

export function editMerchantEnabledCollect(payload) {
  return api('/v1/merchants/editMerchantEnabledCollect', payload);
}

export function mockMerchant(payload) {
  return api('v1/merchants/mockMerchant', payload);
}

export function increaseMerchantPrepaidFee(payload) {
  return api('/v1/merchants/increaseMerchantPrepaidFee', payload);
}

export function getMerchantPrepaidFeeLogList(payload) {
  return api('/v1/merchants/getMerchantPrepaidFeeLogList', payload);
}
