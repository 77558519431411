let copyClickEvent = null;
window.addEventListener('click', (event) => {
  copyClickEvent = event;
});

export default function (str) {
  return new Promise((resolve, reject) => {
    try {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(str);
      } else {
        // must be slower than window event
        setTimeout(() => {
          const tempInput = document.createElement('textarea');
          tempInput.value = str;
          tempInput.style.opacity = 0;
          tempInput.style.position = 'fixed';

          copyClickEvent.target.appendChild(tempInput);

          tempInput.select();
          document.execCommand('copy');
          getSelection().removeAllRanges();
          tempInput.blur();

          tempInput.remove();
        });
      }

      resolve({ success: true });
    } catch (error) {
      reject({ error });
    }
  });
}
