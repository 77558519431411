export default {
  home: {
    read: false
  },
  merchant_list: {
    read: false,
    add: false,
    status: false,
    collect_order: false,
    capital_management: false,
    password_management: false,
    place_order: false,
    edit: false,
    fee_percent_setting: false,
    collect_rule: false,
    pay_rule: false,
    remove: false
  },
  merchant_type_list: {
    read: false,
    edit: false,
    add: false,
    remove: false
  },
  collect_order_list: {
    approve_replenishment: false,
    read: false,
    details: false,
    replenishment: false,
    notify: false,
    view_fee: false,
    view_credit_amt: false,
    third_party_collect_order_list: false, // 沒用到
    read_all_cards: false
  },
  not_process_collect_order_list: {
    read: false,
    read_all_cards: false
  },
  black_list_collect_order_list: {
    read: false,
    remove: false,
    edit: false,
    add: false
  },
  pay_order_list: {
    show: false,
    assign: false,
    edit: false,
    read: false,
    setting: false,
    transfer: false,
    release: false,
    view_fee: false,
    read_all_cards: false
  },
  api_pay_order_list: {
    show: false,
    assign: false,
    edit: false,
    read: false,
    notify: false,
    setting: false,
    transfer: false,
    release: false,
    view_fee: false,
    read_all_cards: false
  },
  black_list_pay_order_list: {
    read: false,
    remove: false,
    edit: false,
    add: false
  },
  capital_log_list: {
    export: false,
    read: false
  },
  collect_orders_leaderboard: {
    read: false
  },
  frozen_log_list: {
    read: false,
    add: false,
    edit: false,
    unfreeze: false,
    edit_freeze_note: false
  },
  collect_rule: {
    read: false,
    edit: false
  },
  pay_rule: {
    read: false,
    edit: false
  },
  collect_channel_settings: {
    read: false,
    add: false,
    edit: false,
    status: false,
    avoid_duplicate: false,
    rule_setting: false,
    remove: false,
    fee_percent_setting: false
  },
  cards_list: {
    remove: false,
    status: false,
    add: false,
    read: false,
    edit: false,
    rule_setting: false,
    enabled_auto_collect: false,
    read_all_cards: false
  },
  platform_billing: {},
  pay_channel_settings: {},
  platform_order_reports: {
    export: false,
    read: false
  },
  merchant_accounting_reports: {
    export: false,
    read: false
  },
  agent_accounting_reports: {
    export: false,
    read: false
  },
  channel_accounting_reports: {
    read: false
  },
  sms_list: {
    read: false
  },
  signin_log_list: {
    read_self: false,
    read_all: false
  },
  operation_log_list: {
    read: false
  },
  backup_logs: {
    download: false,
    read: false
  },
  user_list: {
    remove: false,
    add: false,
    read: false,
    edit: false,
    google_2fa_secret: false
  },
  role_list: {
    remove: false,
    add: false,
    read: false,
    edit: false,
    list: false,
    details: false
  },
  menu_settings: {
    remove: false,
    add: false,
    read: false,
    edit: false
  },
  platform_settings: {
    read: false,
    edit: false
  },
  password_settings: {
    read: false,
    edit: false
  },
  clear_cache: {
    clear_cache: false,
    read: false
  },
  system_version: {
    read: false,
    upgrade: false,
    contact: false
  }
};
