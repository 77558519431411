/**
 * @interface Route
 *
 * @param { String } icon - mdi icon
 * @param { String } customIcon - custom svg icons added at '@/icons'
 * @param { String } name - route name
 * @param { String } path - path for route
 * @param { Boolean } hidden - visibility in drawer by style display
 * @param { Boolean } dontExist - visibility in drawer by VNode life cycle
 * @param { Array<Route> } children - children route
 * @param { Boolean } disabled - won't push route when true
 * @param { Boolean } expandable - expandable
 */

export const mrRoutes = [
  {
    icon: '',
    name: '',
    text: '',
    path: '/vip/',
    component: () => import('../mrPage/index.vue'),
    redirect: '/vip/overview',
    hidden: true,
    children: [
      {
        icon: 'mdi-home-variant-outline',
        name: 'mr首頁',
        text: '首頁',
        path: '/vip/overview',
        component: () => import('../mrPage/overview.vue')
      },

      {
        icon: 'mdi-format-list-bulleted-triangle',
        name: 'mr線上儲值',
        text: '線上儲值',
        expandable: true,
        path: '/vip/collect',
        redirect: '/vip/collect/charge',
        component: () => import('../mrPage/collect/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            name: 'mr餘額儲值',
            text: '餘額儲值',
            path: '/vip/collect/charge',
            component: () => import('../mrPage/collect/charge.vue')
          }
        ]
      },

      {
        icon: 'mdi-format-list-bulleted-type',
        name: 'mr訂單管理',
        text: '訂單管理',
        expandable: true,
        path: '/vip/order',
        redirect: '/vip/order/collect',
        component: () => import('../mrPage/order/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            name: 'mr代收訂單',
            text: '代收訂單',
            path: '/vip/order/collect',
            component: () => import('../mrPage/order/collect.vue')
          },
          {
            icon: 'mdi-circle-outline',
            name: 'mr未認領訂單',
            text: '未認領訂單',
            path: '/vip/order/not-processed',
            component: () => import('../mrPage/order/not-processed.vue')
          },
          {
            icon: 'mdi-circle-outline',
            name: 'mr代付訂單',
            text: '代付訂單',
            showPath: true,
            path: '/vip/order/pay',
            component: () => import('../mrPage/order/pay.vue'),
            children: [
              {
                icon: 'mdi-circle-outline',
                name: 'mr代付歷史紀錄',
                text: '歷史紀錄',
                path: '/vip/order/history/:bank_card_no',
                hidden: true,
                component: () => import('../mrPage/order/_card_number.vue')
              }
            ]
          }
        ]
      },

      {
        icon: 'mdi-currency-usd',
        name: 'mr財務管理',
        text: '財務管理',
        expandable: true,
        path: '/vip/financial',
        redirect: '/vip/financial/history',
        component: () => import('../mrPage/financial/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            name: 'mr帳變紀錄',
            text: '帳變紀錄',
            path: '/vip/financial/history',
            component: () => import('../mrPage/financial/history.vue')
          },
          {
            icon: 'mdi-circle-outline',
            name: 'mr凍結資金',
            text: '凍結資金',
            path: '/vip/financial/frozen',
            component: () => import('../mrPage/financial/frozen.vue')
          }
        ]
      },

      {
        icon: 'mdi-tune-vertical-variant',
        name: 'mr提現管理',
        text: '提現管理',
        expandable: true,
        path: '/vip/pay',
        redirect: '/vip/pay/request',
        component: () => import('../mrPage/pay/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            name: 'mr申請提現',
            text: '申請提現',
            path: '/vip/pay/request',
            component: () => import('../mrPage/pay/request.vue')
          },
          {
            icon: 'mdi-circle-outline',
            name: 'mr提現紀錄',
            text: '提現紀錄',
            path: '/vip/pay/history',
            showPath: true,
            component: () => import('../mrPage/pay/history.vue'),
            children: [
              {
                icon: 'mdi-circle-outline',
                name: 'mr歷史紀錄',
                text: '歷史紀錄',
                path: '/vip/pay/history/:bank_card_no',
                hidden: true,
                component: () => import('../mrPage/pay/_card_number.vue')
              }
            ]
          }
        ]
      },

      {
        icon: 'mdi-transit-detour',
        name: 'mr通道管理',
        text: '通道管理',
        expandable: true,
        path: '/vip/channel',
        redirect: '/vip/channel/fee',
        component: () => import('../mrPage/channel/index.vue'),
        children: [
          // {
          //   icon: 'mdi-circle-outline',
          //   name: 'mr通道費率',
          //   text: '通道費率',
          //   path: '/vip/channel/fee',
          //   component: () => import('../mrPage/channel/fee.vue')
          // },
          {
            icon: 'mdi-circle-outline',
            name: 'mr成交機率',
            text: '成交機率',
            path: '/vip/channel/success-rate',
            showPath: true,
            component: () => import('../mrPage/channel/success-rate.vue'),
            children: [
              {
                icon: 'mdi-circle-outline',
                name: 'mr子帳戶清單',
                text: '子帳戶清單',
                path: '/vip/channel/success-rate/:channel_type_id',
                hidden: true,
                component: () => import('../mrPage/channel/_channel_type.vue')
              }
            ]
          },
          {
            icon: 'mdi-circle-outline',
            name: 'mr串接文件',
            text: '串接文件',
            path: '/vip/channel/api-docs',
            component: () => import('../mrPage/channel/api-docs.vue')
          }
        ]
      },

      {
        icon: 'mdi-chart-line',
        name: 'mr商戶資料_',
        text: '商戶資料',
        expandable: true,
        path: '/vip/info',
        redirect: '/vip/info/details',
        component: () => import('../mrPage/info/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            name: 'mr商戶資料',
            text: '商戶資料',
            path: '/vip/info/details',
            component: () => import('../mrPage/info/details.vue')
          },
          {
            icon: 'mdi-circle-outline',
            name: 'mr登入密碼',
            text: '登入密碼',
            path: '/vip/info/login-password',
            component: () => import('../mrPage/info/login-password.vue')
          },
          {
            icon: 'mdi-circle-outline',
            name: 'mr支付密碼',
            text: '支付密碼',
            path: '/vip/info/pay-password',
            component: () => import('../mrPage/info/pay-password.vue')
          }
        ]
      }
    ]
  },

  {
    icon: '',
    name: 'mr系統',
    text: '系統',
    path: '/vip/system',
    redirect: '/vip/system/access',
    component: () => import('../mrPage/system.vue'),
    children: [
      // {
      //   icon: 'mdi-circle',
      //   name: 'mr權限管理',
      //   text: '權限管理',
      //   path: '/vip/system/access',
      //   redirect: '/vip/system/access/users',
      //   expandable: true,
      //   component: () => import('../mrPage/system/access/index.vue'),
      //   children: [
      //     {
      //       icon: 'mdi-circle-outline',
      //       name: 'mr人員列表',
      //       text: '人員列表',
      //       path: '/vip/system/access/users',
      //       component: () => import('../mrPage/system/access/users.vue')
      //     },
      //     {
      //       icon: 'mdi-circle-outline',
      //       name: 'mr角色管理',
      //       text: '角色管理',
      //       path: '/vip/system/access/roles',
      //       component: () => import('../mrPage/system/access/roles.vue')
      //     }
      //   ]
      // },
      {
        icon: 'mdi-circle',
        name: 'mr系統管理',
        text: '系統管理',
        path: '/vip/system/logs',
        redirect: '/vip/system/logs/login-log',
        expandable: true,
        component: () => import('../mrPage/system/logs/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            name: 'mr登入日誌',
            text: '登入日誌',
            path: '/vip/system/logs/login-log',
            component: () => import('../mrPage/system/logs/login-log.vue')
          },
          // {
          //   icon: 'mdi-circle-outline',
          //   name: 'mr操作日誌',
          //   text: '操作日誌',
          //   path: '/vip/system/logs/action-log',
          //   component: () => import('../mrPage/system/logs/action-log.vue')
          // },
          {
            icon: 'mdi-circle-outline',
            name: 'mr系統版本',
            text: '系統版本',
            path: '/vip/system/logs/version',
            component: () => import('../mrPage/system/logs/version.vue')
          }
        ]
      }
    ]
  }
];
