<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-menu
        v-bind="menuProps"
        ref="menu"
        v-model="menu"
        :close-on-click="false"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
        @update:return-value="update"
      >
        <template #activator="{}">
          <v-text-field
            v-on="on"
            ref="INPUT"
            rounded
            :value="parseTimeDisplay"
            @change="handleChange"
            @input="handleInput"
            v-bind="$attrs"
          >
            <template #prepend-inner>
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template #append>
              <v-menu>
                <template #activator="{ on }">
                  <v-icon v-on="on">mdi-lightning-bolt-outline</v-icon>
                </template>

                <v-list>
                  <v-list-item
                    v-for="option in quickOptions"
                    :key="option.text"
                    @click="handleQuickOptionPick(option.key)"
                  >
                    {{ option.text }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-text-field>
        </template>

        <v-card>
          <v-date-picker
            v-bind="datePickerProps"
            v-model="date"
            @input="callTimePanel"
            @click.native="handlePosition"
            :active-picker.sync="activePicker"
            :range="range"
            color="Primary"
            class="b-rad-0"
            locale="zh-CN"
            no-title
            flat
          ></v-date-picker>

          <v-menu
            v-if="withTime"
            v-model="isTimePanelShow"
            absolute
            :positionX="X"
            :positionY="Y"
            :close-on-content-click="false"
          >
            <div v-if="isTimePanelShow">
              <v-time-picker
                v-if="i === 0"
                v-bind="timePickerProps"
                :value="time"
                @input="(v) => (time = v)"
                @click:minute="isTimePanelShow = false"
                scrollable
                class="b-rad-0"
                format="24hr"
              ></v-time-picker>

              <v-time-picker
                v-if="i === 1"
                v-bind="timePickerProps"
                :value="timeEnd"
                @input="(v) => (timeEnd = v)"
                @click:minute="isTimePanelShow = false"
                scrollable
                class="b-rad-0"
                format="24hr"
              ></v-time-picker>
            </div>
          </v-menu>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="primary" @click="save">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>

    <span>若為範圍以 , 分隔起迄</span>
  </v-tooltip>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    menuProps: {
      tpye: Object,
      default: () => ({})
    },
    datePickerProps: {
      tpye: Object,
      default: () => ({})
    },
    timePickerProps: {
      tpye: Object,
      default: () => ({})
    },
    year: {
      type: Boolean,
      default: false
    },
    withTime: {
      type: Boolean,
      default: false
    },
    range: {
      type: Boolean,
      default: false
    },
    toHour: {
      type: Boolean,
      default: false
    },
    toMinute: {
      type: Boolean,
      default: false
    },
    noRange: {
      type: Boolean,
      default: false
    },
    futureOnly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    moment,
    i: 0, // time page
    activePicker: null,
    fullTimeValue: '',
    date: null,
    time: null,
    timeEnd: null,
    isTimePanelShow: false,
    menu: false,
    //
    X: 0,
    Y: 0
  }),

  computed: {
    parseTimeDisplay() {
      if (!this.fullTimeValue) return '';
      return Array.isArray(this.fullTimeValue)
        ? this.fullTimeValue.map((v) =>
            moment(v).format(
              this.withTime ? `YYYY-MM-DD ${this.timeFormat.f}` : 'YYYY-MM-DD'
            )
          )
        : moment(this.fullTimeValue).format(
            this.withTime ? `YYYY-MM-DD ${this.timeFormat.f}` : 'YYYY-MM-DD'
          );
    },
    timeFormat() {
      if (this.toMinute) return { f: 'H:mm', v: '00:00' };
      if (this.toHour) return { f: 'H', v: '00' };
      return { f: 'H:mm:ss', v: '00:00:00' };
    },
    quickOptions() {
      return this.futureOnly
        ? [
            { text: '明天', key: 'tomorrow' },
            { text: '本週', key: 'thisWeek' },
            { text: '本月', key: 'thisMonth' }
          ]
        : [
            { text: '今日', key: 'today' },
            { text: '昨日', key: 'yesterday' },
            { text: '本週', key: 'thisWeek' },
            { text: '上週', key: 'lastWeek' },
            { text: '本月', key: 'thisMonth' },
            { text: '上月', key: 'lastMonth' }
          ];
    }
  },

  watch: {
    menu(v) {
      this.year && v && setTimeout(() => (this.activePicker = 'YEAR'));
    },
    fullTimeValue(v) {
      this.$emit('input', v && v.length ? v : null);
    }
  },

  methods: {
    handleQuickOptionPick(v) {
      let output = [];
      switch (v) {
        case 'today':
          output = [moment().format('YYYY-MM-DD')];
          this.time = this.timeFormat.v;
          break;
        case 'tomorrow':
          output = [moment().add(1, 'day').format('YYYY-MM-DD')];
          this.time = this.timeFormat.v;
          break;
        case 'yesterday':
          output = [
            moment().subtract(1, 'day').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
          ];
          this.time = this.timeFormat.v;
          break;
        case 'thisWeek':
          output = [
            moment().startOf('isoweek').format('YYYY-MM-DD'),
            moment().endOf('isoweek').format('YYYY-MM-DD')
          ];
          this.time = this.timeFormat.v;
          if (!this.noRange) this.timeEnd = this.timeFormat.v;
          break;
        case 'lastWeek':
          output = [
            moment().startOf('isoweek').subtract(7, 'day').format('YYYY-MM-DD'),
            moment().endOf('isoweek').subtract(7, 'day').format('YYYY-MM-DD')
          ];
          this.time = this.timeFormat.v;
          if (!this.noRange) this.timeEnd = this.timeFormat.v;
          break;
        case 'thisMonth':
          output = [
            moment().startOf('month').format('YYYY-MM-DD'),
            moment().endOf('month').format('YYYY-MM-DD')
          ];
          this.time = this.timeFormat.v;
          if (!this.noRange) this.timeEnd = this.timeFormat.v;
          break;
        case 'lastMonth':
          output = [
            moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
            moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
          ];
          this.time = this.timeFormat.v;
          if (!this.noRange) this.timeEnd = this.timeFormat.v;
          break;

        default:
          break;
      }

      if (this.noRange) {
        this.date = this.futureOnly ? output[1] || output[0] : output[0];
      } else {
        this.date = output;
      }

      this.save();
    },
    handlePosition(e) {
      if (e && e.pageX && e.pageY) {
        this.X = e.pageX;
        this.Y = e.pageY;
      }
    },
    callTimePanel(v) {
      if (!this.withTime) return;

      this.$nextTick(() => {
        this.i = Array.isArray(v) && v.length > 1 ? 1 : 0;

        this.isTimePanelShow = true;
      });
    },

    handleChange(v) {
      if (!v) {
        this.clearAll();
        this.$refs.INPUT.clearableCallback();
        return;
      }
      const values = v.split && v.split(',');
      if (!values) return;
      const startValue = values[0];
      const endValue = values[1];
      const startDate = startValue ? moment(values[0]) : null;
      const endDate = endValue ? moment(values[1]) : null;

      if (
        (startDate !== null && !startDate._isValid) ||
        (endDate !== null && !endDate._isValid)
      ) {
        this.clearAll();
        this.$refs.INPUT.clearableCallback();
        return;
      }

      this.date = [startDate, this.noRange ? null : endDate]
        .filter((v) => !!v)
        .map((v) => v.format('YYYY-MM-DD'));
      this.time = startDate
        ? startDate.format(this.timeFormat.f)
        : this.timeFormat.v;
      if (!this.noRange) {
        this.timeEnd = endDate
          ? endDate.format(this.timeFormat.f)
          : this.timeFormat.v;
      }
      this.save();
    },
    handleInput(v) {
      // v === null && this.clearAll();
    },

    save() {
      let date = this.date || moment().format('YYYY-MM-DD');
      const time = this.time || this.timeFormat.v;
      const timeEnd = this.timeEnd || this.timeFormat.v;
      if (Array.isArray(date)) {
        this.fullTimeValue = date.map((el, i) =>
          this.withTime
            ? moment(`${el} ${!i ? time : timeEnd}`).toISOString()
            : moment(el).toISOString()
        );
      } else {
        this.fullTimeValue = this.withTime
          ? moment(`${date} ${time}`).toISOString()
          : moment(date).toISOString();
      }
      this.$refs.menu.save(this.fullTimeValue);
    },
    update(v) {
      if (!v && !this.fullTimeValue) {
        this.i = 0;
        this.date = null;
        this.time = null;
        this.timeEnd = null;
      }
    },
    $reset() {
      this.clearAll();
    },
    clearAll() {
      this.i = 0;
      this.fullTimeValue = '';
      this.date = null;
      this.time = null;
      this.timeEnd = null;
    }
  }
};
</script>
