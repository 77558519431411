<template>
  <v-card
    :img="computedAssets.bg"
    width="100vw"
    height="100vh"
    class="pos-r"
    tile
  >
    <div class="right_side">
      <div class="warning_block px-5 py-4 px-sm-7 py-sm-9 white--text">
        <div class="d-flex align-center">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.9855 10.6557C19.4552 10.9716 18.7243 11.3664 17.8174 11.7592C16.1672 12.4739 13.9151 13.1899 11.2187 13.3989C11.217 13.429 11.2152 13.4596 11.2135 13.4904C11.1585 14.4644 11.0926 15.7969 11.0488 17.2339C10.9584 20.1925 10.9734 23.3272 11.2818 24.8239L11.2828 24.8289C11.3 24.9135 11.3807 25.136 11.6425 25.506C11.8933 25.8604 12.2513 26.2679 12.7081 26.7159C13.6223 27.6123 14.8179 28.5604 16.0387 29.4415C17.2515 30.3168 18.45 31.0981 19.3484 31.662C19.5836 31.8097 19.7976 31.942 19.9851 32.0568C20.1729 31.9419 20.3872 31.8094 20.6229 31.6616C21.5224 31.0975 22.7231 30.316 23.9398 29.4402C25.1643 28.5589 26.3664 27.6096 27.2895 26.7114C27.7508 26.2625 28.1145 25.8531 28.3714 25.4958C28.6268 25.1404 28.7186 24.9163 28.7441 24.8162C29.0255 23.3219 29.0381 20.1965 28.9553 17.25C28.9147 15.8082 28.8539 14.4694 28.8031 13.4904C28.8015 13.4597 28.7999 13.4293 28.7983 13.3992C26.0823 13.1908 23.8189 12.4752 22.1615 11.7601C21.2504 11.367 20.5171 10.9718 19.9855 10.6557ZM30.3752 11.7929C24.0863 11.7929 19.9849 8.66669 19.9849 8.66669C19.9849 8.66669 15.8836 11.7929 9.64945 11.7929C9.64945 11.7929 8.93855 21.7107 9.64945 25.1603C10.3057 28.3943 19.9849 34 19.9849 34C19.9849 34 29.6643 28.3943 30.3752 25.1603C31.0314 21.7645 30.3752 11.7929 30.3752 11.7929Z"
              fill="white"
            />
            <path
              d="M22.22 16.8485C22.22 18.0535 21.2213 19.0303 19.9893 19.0303C18.7573 19.0303 17.7586 18.0535 17.7586 16.8485C17.7586 15.6435 18.7573 14.6667 19.9893 14.6667C21.2213 14.6667 22.22 15.6435 22.22 16.8485Z"
              fill="white"
            />
            <path
              d="M16.8609 23.0258C16.9535 21.3962 18.3315 20.1212 20.0002 20.1212C21.6688 20.1212 23.0469 21.3962 23.1394 23.0258L23.3331 26.4364C23.3402 26.5615 23.2385 26.6667 23.1104 26.6667H16.8899C16.7618 26.6667 16.6601 26.5615 16.6672 26.4364L16.8609 23.0258Z"
              fill="white"
            />
          </svg>

          警告！
        </div>
        <SizeBox height="16" />
        <div>
          任何未經授權而嘗試進入本系統的人員，已經觸及法律，請立即終止，否則將受法律制裁。
        </div>
      </div>
    </div>
    <v-card
      color="transparent"
      flat
      class="login_card px-4 pa-sm-12"
      :class="{ isMr }"
      dark
    >
      <v-card-title class="flex-center flex-column">登入</v-card-title>
      <SizeBox v-if="!$vuetify.breakpoint.smAndDown" height="42" />

      <v-window :value="page">
        <v-window-item :value="1">
          <v-card-text>
            <v-form ref="form" @submit.prevent="submit">
              <v-text-field
                background-color="#21212166"
                rounded
                label="帳號"
                v-model="account"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
              <SizeBox v-if="!$vuetify.breakpoint.smAndDown" height="12" />
              <v-text-field
                background-color="#21212166"
                rounded
                label="密碼"
                v-model="password"
                :rules="[$rules.required]"
                :type="peekPassword ? 'text' : 'password'"
                :append-icon="peekPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="peekPassword = !peekPassword"
                outlined
              ></v-text-field>
              <SizeBox v-if="!$vuetify.breakpoint.smAndDown" height="12" />

              <div class="d-flex">
                <v-img
                  width="120"
                  height="60"
                  :src="captchaImg"
                  contain
                  class="b-rad-8"
                >
                  <div class="fill-all flex-center">
                    <v-progress-circular
                      v-if="captchaLoading"
                      :value="20"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </v-img>

                <SizeBox width="12" />

                <v-text-field
                  background-color="#21212166"
                  rounded
                  label="輸入驗證碼"
                  v-model="captcha"
                  :rules="[$rules.required]"
                  :error-messages="captchaError"
                  prepend-icon="mdi-cached"
                  @focus="captchaError = null"
                  @click:prepend="getCaptcha"
                  outlined
                  @keyup.enter="submit"
                ></v-text-field>
              </div>
            </v-form>
            <SizeBox v-if="!$vuetify.breakpoint.smAndDown" height="12" />
            <v-btn rounded color="primary" block height="40" @click="submit">
              登入
            </v-btn>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text>
            <v-form ref="otp_form" @submit.prevent="submitOtpCode()">
              <v-text-field
                autofocus
                background-color="#21212166"
                rounded
                label="Google 驗證碼"
                v-model="otpCode"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
              <SizeBox v-if="!$vuetify.breakpoint.smAndDown" height="12" />
            </v-form>
          </v-card-text>
          <SizeBox v-if="!$vuetify.breakpoint.smAndDown" height="12" />

          <v-card-actions>
            <v-btn
              rounded
              color="primary"
              block
              height="48"
              @click="submitOtpCode()"
            >
              驗證
            </v-btn>
          </v-card-actions>
        </v-window-item>
        <v-window-item :value="3">
          <v-card-text>建立安全驗證</v-card-text>
          <v-card-text>
            <v-form ref="bind_otp_form" @submit.prevent="connectOtp">
              <v-img :src="secret.data_url"></v-img>
              <v-text-field
                background-color="#21212166"
                rounded
                label="Google 驗證碼"
                v-model="otpCode"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
              <SizeBox v-if="!$vuetify.breakpoint.smAndDown" height="12" />
            </v-form>
          </v-card-text>
          <SizeBox v-if="!$vuetify.breakpoint.smAndDown" height="12" />

          <v-card-actions>
            <v-btn
              rounded
              color="primary"
              block
              height="40"
              @click="connectOtp"
            >
              啟用安全驗證
            </v-btn>
          </v-card-actions>
        </v-window-item>
      </v-window>
    </v-card>
  </v-card>
</template>

<script>
import { loginByPasswordOTP } from '@/api/session';
import { loginByPasswordOTP as mrLoginByPasswordOTP } from '@/api/merchantCenter/session';

import {
  checkCaptcha,
  getCaptcha,
  checkSecret,
  makeSecret,
  enableOTP,
  checkPassword
} from '@/api/2fa';

export default {
  data() {
    return {
      account: '',
      password: '',
      peekPassword: false,

      captcha: '',
      captchaImg: '',
      captchaLoading: false,
      captchaError: null,
      otpCode: '',
      page: 1,
      secret: {
        data_url: '',
        auth_url: ''
      }
    };
  },

  computed: {
    isMr() {
      return /vip/.test(location.pathname.split('/')[1]);
    },
    computedAssets() {
      return this.isMr
        ? {
            bg: require(`@/assets/images/mr_login_bg.jpeg`)
          }
        : {
            bg: this.$vuetify.breakpoint.smAndDown
              ? require(`@/assets/images/bg.jpeg`)
              : require(`@/assets/images/login_bg.jpeg`)
          };
    }
  },

  created() {
    this.getCaptcha();
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      const { success } = await checkCaptcha({
        captcha_code: this.captcha,
        captcha_data: this.captchaImg
      });

      if (!success) {
        this.captchaError = '驗證碼錯誤';
        return;
      }

      const Checked = await checkPassword({
        account: this.account,
        password: this.password
      });

      if (!Checked.password_valid) {
        this.$toast.error('帳號或密碼錯誤');
        return;
      }

      const { otp_enabled, error } = await checkSecret(this.account);

      if (typeof otp_enabled !== 'boolean') {
        this.$toast.error(error.message || '登入失敗');
        return;
      }

      this.page = otp_enabled ? 2 : 3;

      if (!otp_enabled) {
        const { data_url, auth_url } = await makeSecret({
          account: this.account,
          password: this.password
        });
        this.secret.data_url = data_url;
        this.secret.auth_url = auth_url;
      }
    },
    async getCaptcha() {
      this.captcha = '';
      this.captchaImg = '';
      this.captchaLoading = true;
      const { dataUrl } = await getCaptcha();
      this.captchaImg = dataUrl;
      this.captchaLoading = false;
    },

    async submitOtpCode(retry = false) {
      let firedApi = this.isMr ? mrLoginByPasswordOTP : loginByPasswordOTP;
      if (this.isMr) {
        firedApi = retry ? loginByPasswordOTP : mrLoginByPasswordOTP;
      } else {
        firedApi = retry ? mrLoginByPasswordOTP : loginByPasswordOTP;
      }
      const { token, refresh_token, error } = await firedApi({
        account: this.account,
        password: this.password,
        otp_code: this.otpCode
      });

      if (error) {
        if (error.code === 5) {
          this.submitOtpCode(true);
          return;
        }
        this.$toast.error(error.message || '登入失敗');
        this.page = 1;
        return;
      }

      if (token && refresh_token) {
        this.$store.dispatch('auth/SET_TOKENS', { token, refresh_token });
        const isLogin = await this.$store.dispatch('auth/CHECK_LOGIN_STATUS');
        if (isLogin) {
          location.reload();
        }
      }
    },
    async connectOtp() {
      const { success, error } = await enableOTP({
        account: this.account,
        otp_code: this.otpCode
      });

      if (success) {
        this.submitOtpCode();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login_card {
  position: absolute;
  width: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    position: relative;
    width: 100%;
    transform: translate(0);
    top: auto;
    bottom: auto;
  }
}

.right_side {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
  }
}

.warning_block {
  width: 80%;
  background-color: #ffffff55;
  border-radius: 14px;
  margin-bottom: 30%;
  backdrop-filter: blur(6px);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-bottom: 0;
    margin-top: 16px;
  }
}
</style>
