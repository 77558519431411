<template>
  <v-menu offset-y nudge-top="-5">
    <template #activator="{ on }">
      <v-btn
        rounded
        :fab="$vuetify.breakpoint.smAndDown"
        height="100%"
        text
        v-on="on"
        class="text-none"
      >
        <template v-if="$vuetify.breakpoint.smAndDown">
          <v-icon>mdi-menu</v-icon>
        </template>
        <div v-else class="text-left">
          <div class="Medium text-no-wrap">使用者：{{ User.name }}</div>
          <div v-if="User.role" class="text-no-wrap">
            角色：{{ User.role.name }}
          </div>
        </div>
      </v-btn>
    </template>

    <v-card color="Secondary800">
      <v-list rounded class="transparent" dark>
        <v-list-item v-if="$vuetify.breakpoint.smAndDown" class="text-left">
          <div>
            <div class="Medium text-no-wrap">使用者：{{ User.name }}</div>
            <div v-if="User.role" class="text-no-wrap">
              角色：{{ User.role.name }}
            </div>
          </div>
        </v-list-item>

        <v-list-item @click="$store.dispatch('auth/DO_LOGOUT')">
          <v-icon>mdi-logout</v-icon>
          登出
        </v-list-item>

        <template v-if="isSimulatingMerchant">
          <v-list-item>
            <v-icon>mdi-triangle-outline</v-icon>
            模擬中: {{ Merchant.name }}
          </v-list-item>
          <v-divider></v-divider>
          <template v-if="isMrRoute">
            <v-list-item @click="endSimulating('close')">
              <v-icon color="error">mdi-close</v-icon>
              <span class="error--text">結束模擬</span>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item @click="enterMerchantCenter('open')">
              <v-icon color="merchant">mdi-arrow-top-right-bold-box</v-icon>
              <span class="merchant--text">進入商戶中心</span>
            </v-list-item>
            <v-list-item @click="endSimulating">
              <v-icon color="error">mdi-close</v-icon>
              <span class="error--text">結束模擬</span>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      logStatus: 'auth/getLogStatus',
      User: 'auth/getUser',
      Merchant: 'auth/getMerchant'
    }),
    isLogin() {
      return this.logStatus === 'login';
    },
    avatar() {
      return this.User && this.User.avatar;
    },
    isSimulatingMerchant() {
      return this.Merchant;
    },
    isMrRoute() {
      return /vip/.test(this.$route.path.split('/')[1]);
    }
  },

  methods: {
    endSimulating(method) {
      localStorage.removeItem('mrId');
      this.$store.dispatch('auth/CLEAR_MERCHANT');

      switch (method) {
        case 'back':
          location.href = location.origin;
          break;
        case 'close':
          window.close();
          break;
        default:
          break;
      }

      this.$toast('已結束模擬');
    },

    enterMerchantCenter(method) {
      switch (method) {
        case 'replace':
          location.href = `${location.origin}/mr`;
          break;
        case 'open':
          window.open(`${location.origin}/mr`);
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
