<template>
  <v-autocomplete
    :label="label"
    :value="value"
    @focus="searchOnFocus && doAutocompleteSearch()"
    @input="(v) => $emit('input', v)"
    :items="options"
    :no-data-text="
      !!searchingInput && !(isTyping || isSerching) ? noDataText : placehoder
    "
    @update:search-input="handleInput"
    outlined
    v-bind="$attrs"
    clearable
  >
    <template #append>
      <Async :loading="isTyping || isSerching" inline>
        <v-icon>mdi-menu-down</v-icon>
      </Async>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    api: {
      type: Function,
      default: () => {}
    },
    limit: {
      type: Number,
      default: 20
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    noDataText: {
      type: String,
      default: '無結果'
    },
    placehoder: {
      type: String,
      default: '輸入以搜尋'
    },
    searchOnFocus: {
      type: Boolean,
      default: true
    },
    default: {
      type: String,
      default: ''
    },
    keyName: {
      type: String,
      default: 'id'
    },
    textName: {
      type: String,
      default: 'name'
    },
    exclude: {
      type: [String, Array],
      default: null
    }
  },
  data() {
    return {
      isTyping: false,
      typingBuffer: null,
      isSerching: false,
      searchingInput: '',
      options: []
    };
  },
  methods: {
    handleInput(v) {
      clearTimeout(this.typingBuffer);
      this.typingBuffer = null;
      this.isTyping = true;
      this.searchingInput = v;
      this.$emit('update:text', v);

      this.typingBuffer = setTimeout(() => {
        this.isTyping = false;
        this.doAutocompleteSearch();
      }, 400);
    },

    async doAutocompleteSearch() {
      this.isSerching = true;

      let items = [];
      const Result = await this.api({
        query: this.searchingInput,
        limit: this.limit
      });

      if (!Result.error) {
        if (Array.isArray(Result)) {
          items = Result;
        } else if (Result.items && Array.isArray(Result.items)) {
          items = Result.items;
        }

        this.options = items
          .map((el) => ({
            text: el.name || el.note || el[this.textName],
            value: el[this.keyName]
          }))
          .filter(({ value }) =>
            Array.isArray(this.exclude)
              ? !this.exclude.includes(value)
              : value !== this.exclude
          );

        if (this.default) {
          const v = this.options.find(({ value }) => this.default === value);
          if (v) this.$emit('input', v.value);
        }
      }

      this.isSerching = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>