import axios from 'axios';
import Vue from 'vue';
import store from '../store';
import { mrRenewToken } from './merchantCenter/session';
import { renewToken } from './session';

const baseURL = Vue.$apiURL || process.env.VUE_APP_API_URL || location.origin;

const service = axios.create({
  baseURL,
  timeout: 30000
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = localStorage.getItem('at');

    config.headers['Access-Control-Allow-Origin'] = '*';
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    console.error('error:', error); // for debug
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    let fe_version = response.headers['fe-version'] || '1.5.0.220111';
    if (fe_version !== localStorage.getItem('fe-version')) {
      localStorage.setItem('fe-version', fe_version);
      window.location.reload(); // For new version, simply reload on any get
    }
    return response.data;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      const rt = localStorage.getItem('rt');
      const isMerchant = store.getters['auth/getIsMerchant'];
      const firedApi = isMerchant ? mrRenewToken : renewToken;
      if (rt) {
        const { token, error } = await firedApi({ refresh_token: rt });
        console.log('isError', error);

        if (!error) {
          store.dispatch('auth/SET_TOKENS', { token /* refresh_token */ });
        }
      }
    }
    return Promise.reject(error);
  }
);

export const api = async (url, data = {}, retry = true) => {
  const headers = {};
  const token = localStorage.getItem('at');
  const mrId =
    (store.getters['auth/getMerchant'] &&
      store.getters['auth/getMerchant'].id) ||
    localStorage.getItem('mrId');
  const method = 'post';
  const isMrRoute = /vip/.test(location.pathname.split('/')[1]);

  headers['Access-Control-Allow-Origin'] = '*';
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  if (data === 'no-data') {
    data = {};
  } else {
    if (mrId && isMrRoute) {
      data.merchant_id = mrId;
    }
  }

  try {
    const result = await service.request({
      method,
      url,
      data,
      headers
    });
    return result;
  } catch (error) {
    const detail = error.response
      ? error.response.data || error.response
      : error;

    if (error.response && error.response.status === 401) {
      //
    } else {
      Vue.$toast.error(
        `error: ${error}\ndetail: ${
          typeof detail === 'object' ? JSON.stringify(detail) : detail
        }`
          .trim()
          .replace(`{"`, `{\n"`)
          .replace(`"}`, `"\n}`)
          .replace(/,"/g, `,\n"`)
      );
    }

    if (error.response && error.response.status === 401 && retry) {
      const result = await api(url, data, false);
      let detail = {};
      const at = localStorage.getItem('at');

      if (result.error) {
        detail = result.error.response
          ? result.error.response.data || result.error.response
          : result.error;
        if (result.error.response.status === 401 && at) {
          store.dispatch('auth/DO_LOGOUT');
          Vue.$toast.error(`連線逾時 請重新登入`);
        }
      }

      return !result.error
        ? result
        : { error: { ...result.error, detail, message: detail.message } };
    }

    return { error: { ...error, detail, message: detail.message } };
  }
};
