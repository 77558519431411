<template>
  <v-dialog
    :value="isOpen"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="reset"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-window v-model="step">
          <SizeBox height="48" />
          <v-window-item :value="0">
            <v-form ref="form">
              <Async
                :loading="loading"
                :class="loading ? ['w100p', 'flex-center'] : null"
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      rounded
                      label="輸入谷歌驗證碼"
                      placeholder="輸入驗證碼"
                      v-model="googleAuth"
                      :rules="[$rules.required]"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </Async>
            </v-form>
          </v-window-item>
          <v-window-item :value="1">
            <div class="fill-all text-DisplaySmall">
              <div class="w100p flex-center">{{ content }}</div>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          @click="reset"
          :disabled="loading"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          v-if="step === 0"
          color="primary"
          dark
          depressed
          large
          @click="submit"
          :loading="loading"
        >
          下一步
        </v-btn>
        <v-btn
          rounded
          v-if="step === 1"
          :color="btn.color || 'primary'"
          dark
          depressed
          large
          @click="confirmChange"
        >
          {{ btn.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  watch: {
    isOpen(v) {
      v ? this.init() : this.reset();
    }
  },

  data() {
    return {
      loading: false,
      googleAuth: '',
      step: 0
    };
  },

  computed: {
    ...mapState({
      isOpen: (state) => state.confirm.isOpen,
      title: (state) => state.confirm.title,
      content: (state) => state.confirm.content,
      btn: (state) => state.confirm.btn,
      callback: (state) => state.confirm.callback,
      needAuth: (state) => state.confirm.needAuth
    })
  },

  methods: {
    init() {
      this.$refs.form && this.$refs.form.reset();
      this.step = this.needAuth ? 0 : 1;
    },
    reset() {
      this.$refs.form && this.$refs.form.reset();
      this.loading = false;
      this.googleAuth = '';
      this.step = 0;

      this.$store.commit('confirm/setConfirmData', {
        isOpen: false,
        title: '',
        content: '',
        btn: {
          text: '',
          color: ''
        },
        callback: () => {}
      });
    },
    submit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      setTimeout(() => {
        this.step++;
        this.loading = false;

        return;
      }, 1500);
    },
    async confirmChange() {
      if (this.callback && typeof this.callback === 'function') {
        await this.callback();
      }
      this.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
