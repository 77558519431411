<template>
  <v-menu
    v-bind="menuProps"
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    @update:return-value="update"
  >
    <template #activator="{ on }">
      <v-text-field
        v-on="on"
        ref="INPUT"
        rounded
        readonly
        :value="value"
        @change="handleChange"
        @input="handleInput"
        v-bind="$attrs"
      ></v-text-field>
    </template>

    <v-card>
      <v-card-text>
        <v-row align="center">
          <v-col cols="auto">
            <v-text-field
              outlined
              rounded
              dense
              hide-details
              type="number"
              v-model="start"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-text-field
              outlined
              rounded
              dense
              hide-details
              type="number"
              v-model="end"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" rounded depressed small @click="save">
              儲存
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    menuProps: {
      tpye: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      menu: false,
      start: 0,
      end: 0
    };
  },

  watch: {
    value(v) {
      //
    }
  },

  methods: {
    save() {
      this.$emit('input', [this.start, this.end]);
      this.menu = false;
    },
    update(v) {
      //
    },
    $reset() {
      this.clearAll();
    },
    clearAll() {
      this.start = 0;
      this.end = 0;
    },
    handleChange() {
      //
    },
    handleInput() {
      //
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>