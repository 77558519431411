<template>
  <div>
    <v-btn
      rounded
      depressed
      color="primary"
      @click="doFetch"
      :loading="loading"
      :class="{ float: $vuetify.breakpoint.smAndDown }"
    >
      <v-icon v-if="$vuetify.breakpoint.smAndDown">mdi-refresh</v-icon>
      <span v-else>刷新</span>

      <v-progress-circular
        v-if="refreshTime"
        :value="percentage"
        :rotate="-90"
        size="14"
        width="2"
        class="ml-1"
      ></v-progress-circular>

      <v-menu>
        <template #activator="{ on }">
          <v-icon v-on="on" @click.stop>mdi-menu-down</v-icon>
        </template>

        <v-list>
          <v-list-item-group v-model="refreshTime">
            <v-list-item>自動刷新</v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-for="option in refreshOptions"
              :key="`refreshOption:${option.value}`"
              :value="option.value"
            >
              {{ option.text }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    fetchData: {
      type: Function,
      default: () => {},
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoading: false,
      refreshTime: null,
      refreshTicker: null,
      refreshTimeCountDown: 0,
      refreshOptions: [
        { text: '無', value: null },
        { text: '15 秒', value: 15 },
        { text: '30 秒', value: 30 },
        { text: '1 分鐘', value: 60 },
        { text: '3 分鐘', value: 180 }
      ]
    };
  },

  computed: {
    percentage() {
      return Number(
        this.$num.multiply(this.refreshTimeCountDown / this.refreshTime, 100)
      ).toFixed(0);
    }
  },

  watch: {
    refreshTime(v) {
      clearInterval(this.refreshTicker);
      this.refreshTicker = null;
      this.refreshTimeCountDown = v;

      if (v) {
        localStorage.setItem('art', v);
        this.startTicker();
      } else {
        localStorage.removeItem('art');
      }
    }
  },

  mounted() {
    const autoRefreshTime = localStorage.getItem('art');
    this.refreshTime = autoRefreshTime ? Number(autoRefreshTime) : null;

    if (this.refreshTime) {
      this.startTicker();
    }
  },

  beforeDestroy() {
    clearInterval(this.refreshTicker);
    this.refreshTicker = null;
  },

  methods: {
    startTicker() {
      this.refreshTicker = setInterval(() => {
        this.refreshTimeCountDown--;
        if (this.refreshTimeCountDown <= 0) {
          this.refreshTimeCountDown = this.refreshTime;
          this.fetchData();
        }
      }, 1000);
    },
    doFetch() {
      this.fetchData();
      clearInterval(this.refreshTicker);
      this.refreshTicker = null;
      this.refreshTimeCountDown = this.refreshTime;
      if (this.refreshTime) this.startTicker();
    }
  }
};
</script>

<style lang="scss" scoped>
.float {
  position: fixed;
  right: 88px;
  top: 8px;
  z-index: 99;
}
</style>