<template>
  <v-card class="title_with_line Surface100 fill-height" flat>
    <!-- <div class="title_line" v-bind="lineColorBinding"></div> -->
    <div class="titles">
      <div v-if="$slots.title" class="title_with_line-title Secondary600--text">
        <slot name="title" />
      </div>
      <div v-else class="title_with_line-title Secondary600--text">
        {{ title }}
      </div>
      <div
        v-if="$slots.subtitle"
        class="
          title_with_line-subtitle
          Secondary900--text
          text-DisplayRegular
          Medium
        "
      >
        <slot name="subtitle" />
      </div>
      <div
        v-else
        class="
          title_with_line-subtitle
          Secondary900--text
          text-DisplayRegular
          Medium
        "
      >
        {{ subtitle }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },

    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: [String, Number],
      default: ''
    }
  },

  computed: {
    isRawColor() {
      const rawColorRegexp =
        /^(#[0-9a-f]{3}|#(?:[0-9a-f]{2}){2,4}|(rgb|hsl)a?\((-?\d+%?[,\s]+){2,3}\s*[\d.]+%?\))$/;
      return rawColorRegexp.test(this.color);
    },
    lineColorBinding() {
      return this.isRawColor
        ? { style: { backgroundColor: this.color } }
        : { class: `title_line ${this.color}` };
    }
  }
};
</script>

<style lang="scss" scoped>
.title_with_line {
  position: relative;
  display: flex;
  align-items: stretch;

  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;

  .titles {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;

    .title_with_line-title {
      white-space: pre-wrap;
      margin-bottom: 6px;
      line-height: 1;
    }

    .title_with_line-subtitle {
      padding-left: 2px;
    }
  }

  .title_line {
    position: relative;
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 2px;
    margin-right: 4px;
  }
}
</style>
