import { api } from '..';

export function getMerchant(payload) {
  return api('/v1/merchantCenter/merchants/getMerchant', payload);
}

export function editMerchant(payload) {
  return api('/v1/merchantCenter/merchants/editMerchant', payload);
}

export function modifyPassword(payload) {
  return api('/v1/merchantCenter/merchants/modifyPassword', payload);
}

export function modifyPayPassword(payload) {
  return api('/v1/merchantCenter/merchants/modifyPayPassword', payload);
}

export function getApiSecret(payload) {
  return api('/v1/merchantCenter/merchants/getApiSecret', payload);
}

export function getAvailableCollectChannels(payload) {
  return api(
    '/v1/merchantCenter/merchants/getAvailableCollectChannels',
    payload
  );
}
