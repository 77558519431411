import moment from 'moment';
import VueExcelXlsx from 'vue-excel-xlsx';
import clone from 'just-clone';
import { i18n } from './i18n';
import Vue from 'vue';
import App from './App.vue';
// import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { api } from '@/api/index';
import VueRouter from 'vue-router';

import VueCurrencyFilter from 'vue-currency-filter';
import Toast from 'vue-toastification';
import bigDecimal from 'js-big-decimal';

import 'vue-toastification/dist/index.css';
// override toast style
import './scss/toast.scss';

import './scss/main.scss';
import './scss/utils.scss';
import './scss/typography.scss';

// inject global components
import './GlobalComponentsInject.js';

import confirm from './plugins/confirm';
import rules from './plugins/rules';
import commonSchema from './common-schema';
import copy from './plugins/copy';

Vue.config.productionTip = false;
Vue.use(VueRouter);

const toastOptions = {
  position: 'bottom-right',
  timeout: 2200
};

Vue.filter('formatTime', (v) => {
  return v && moment(v).format('YYYY/MM/DD H:mm:ss');
});

Vue.use(VueCurrencyFilter, {
  symbol: '',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined
});

Vue.use(Toast, toastOptions);

Vue.prototype.$num = bigDecimal;
Vue.prototype.$copy = copy;
Vue.prototype.$clone = clone;
Vue.prototype.$commonSchema = commonSchema;
Vue.prototype.$moment = moment;
Vue.prototype.$api = api;
Vue.prototype.$confirm = confirm;
Vue.prototype.$rules = rules;
Vue.prototype.$apiURL = process.env.VUE_APP_API_URL || location.origin;

Vue.use(VueExcelXlsx);

(async () => {
  const loginStatus = await store.dispatch('auth/CHECK_LOGIN_STATUS');
  await store.dispatch('auth/FETCH_MERCHAT');

  if (loginStatus) {
    if (loginStatus === 'user') {
      await store.dispatch('app/FETCH_SITE_SETTINGS');
      await store.dispatch('auth/FETCH_GRANTS');
    } else if (loginStatus === 'merchant') {
      //
    }
  }

  Vue.prototype.$grants = store.getters['auth/getGrants'];
  Vue.prototype.$getGrantValue = store.getters['auth/getGrantValue'];

  const { routes } = require('./router/routes');
  const { mrRoutes } = require('./router/mrRoutes');
  const filteredRoutes = routes();

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [...filteredRoutes, ...mrRoutes]
  });

  const isMrRoute = /vip/.test(location.pathname.split('/')[1]);
  if (loginStatus === 'merchant' && !isMrRoute) {
    router.replace('/vip/');
  } else if (loginStatus === 'user' && isMrRoute) {
    router.replace('/');
  }

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App)
  }).$mount('#app');
})();

window.addEventListener('storage', () => {
  store.dispatch('auth/FETCH_MERCHAT');
});
