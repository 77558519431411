export const theme1 = {
  light: {
    // override for origin vuetify
    primary: '#912A30',
    secondary: '#2A263C',
    error: '#F64E60',
    success: '#2FB897',
    alert: '#FFA800',
    merchant: '#D38B5D',

    // Primary
    Primary: '#912A30',
    Secondary: '#2A263C',
    Surface: '#ffffff',
    Error: '#F64E60',
    Success: '#2FB897',
    Alert: '#FFA800',

    Primary900: '#912A30',
    Primary800: '#9D2E35',
    Primary700: '#C53948',
    Primary600: '#EA3A4D',
    Primary500: '#F64E60',
    Primary400: '#E76F7C',
    Primary300: '#EE8792',
    Primary200: '#F299A3',
    Primary100: '#FECBD1',
    Primary050: '#FFEBED',
    Primary000: '#FDFCF9',

    // Secondary
    Secondary900: '#2A263C',
    Secondary800: '#36324A',
    Secondary700: '#49455B',
    Secondary600: '#858196',
    Secondary500: '#9995A9',
    Secondary400: '#CBC9D0',
    Secondary300: '#D7D7DE',
    Secondary200: '#E0E0E6',
    Secondary100: '#E7E7E7',
    Secondary050: '#F7F3F3',

    // Surface
    Surface500: '#FFFAFA',
    Surface100: '#F9F2F2',
    Surface050: '#EBE6E6',

    // Error
    Error500: '#F64E60',
    Error400: '#ffd3d3',
    Error300: '#EE8792',
    Error200: '#FECBD1',

    // Success
    Success500: '#2FB897',
    Success400: '#61E3C4',
    Success300: '#A4F1DF',
    Success200: '#E3F5F1',

    // Alert
    Alert500: '#FFA800',
    Alert400: '#FDCB4C',
    Alert300: '#FFE195',
    Alert200: '#FFEFC6'
  }
};

export const theme2 = {
  light: {
    // override for origin vuetify
    primary: '#D38B5D',
    secondary: '#FFE278',
    error: '#F71566',
    success: '#2FB897',
    alert: '#D7A648',
    merchant: '#FFBD9B',

    // Primary
    Primary: '#D38B5D',
    Secondary: '#FFE278',
    Success: '#2FB897',
    Surface: '#FBFBFF',
    Error: '#F71566',
    Alert: '#D7A648',

    Primary900: '#3E1E0A',
    Primary800: '#6A3A1B',
    Primary700: '#C2703B',
    Primary600: '#CB7F4F',
    Primary500: '#D38B5D',
    Primary400: '#E7A174',
    Primary300: '#EDAE85',
    Primary200: '#F0BB98',
    Primary100: '#FFD7BD',
    Primary050: '#FEE6D7',
    Primary000: '#DCC5C6',

    // Secondary
    Secondary900: '#172E36',
    Secondary800: '#284A57',
    Secondary700: '#375864',
    Secondary600: '#6F838A',
    Secondary500: '#90A7B0',
    Secondary400: '#BDC6CA',
    Secondary300: '#D3D8DA',
    Secondary200: '#E5E6E7',
    Secondary100: '#EBEBEB',
    Secondary050: '#F4F3F2',

    // Surface
    Surface500: '#FFFBF9',
    Surface100: '#EBEBEB',
    Surface050: '#F3F5F7',

    // OnSurface
    OnSurface900: '#080214',
    OnSurface700: '#2A2635',
    OnSurface600: '#37363A',
    OnSurface500: '#53505B',
    OnSurface400: '#908C95',
    OnSurface200: '#E0E0E0',
    OnSurface100: '#EBEBEB',
    OnSurface050: '#F4F4F4',

    // Error
    Error500: '#F71566',
    Error400: '#FFA4E5',
    Error300: '#FBC4E8',
    Error200: '#FFEAF8',

    // Success
    Success500: '#2FB897',
    Success400: '#61E3C4',
    Success300: '#A4F1DF',
    Success200: '#E3F5F1',

    // OnError
    OnError900: '#F71566',
    OnError600: '#37363A',
    OnError500: '#080214',

    // Alert
    Alert500: '#C9A227',
    Alert400: '#F1CF64',
    Alert300: '#FDE087',
    Alert200: '#FFE9A6',

    // OnAlert
    OnAlert900: '#080214',
    OnAlert600: '#37363A'
  }
};
