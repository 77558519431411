import Vue from 'vue';
import { routes } from '@/router/routes.js';
import { mrRoutes } from '@/router/mrRoutes.js';
import { retrieve } from '@/api/sysconfig';

export default {
  namespaced: true,
  state: () => ({
    isDrawerOpen: true,
    routeTitle: '',
    currentRoute: {},
    cachedRoutes: {},
    includedRoutes: {},
    tempBreadcrumbs: [],

    settingFetched: false,
    settingFileId: '',
    siteSetting: {
      site_title: '',
      site_domain: '',
      cs_id: '',
      block_multiple_login: false,
      whitelisted_login_ips: [],
      max_failed_validates: 0,
      block_cooldown_mins: 0,
      enabled_merchant_pay: false,
      enabled_withdrawal_notify: false,
      enabled_otp: false,
      enabled_pay_api: false,
      enabled_collect_order_replenish_otp: false
    }
  }),

  getters: {
    getIsDrawerOpen: (state) => state.isDrawerOpen,
    getIncludedRoute: (state) => (name) => state.includedRoutes[name] || [],
    getCurrentRoute: (state) => state.currentRoute,
    getRouteTitle: (state) => state.routeTitle,
    getTempBreadcrumbs: (state) => state.tempBreadcrumbs,
    getSettingFetched: (state) => state.settingFetched,
    getSettingFileId: (state) => state.settingFileId,
    getSiteSetting: (state) => state.siteSetting
  },

  mutations: {
    setIsDrawerOpen(state, status) {
      state.isDrawerOpen = !!status;
    },
    setIncludeRoute(state, { name, routes }) {
      Vue.set(state.includedRoutes, name, routes);
    },
    setRouteTitle(state, title) {
      state.routeTitle = title;
    },
    addTempBreadcrumbs(state, item) {
      state.tempBreadcrumbs.push(item);
    },
    cleanTempBreadcrumbs(state) {
      state.tempBreadcrumbs = [];
    },
    setSettingFetched(state, status) {
      state.settingFetched = !!status;
    },
    setSettingFile(state, setting) {
      state.settingFileId = setting.id;
      Vue.set(state, 'siteSetting', setting);
    },
    setCurrentRoute(state, status) {
      Vue.set(state, 'currentRoute', status);
    },
    setCacheRoute(state, { name, data }) {
      Vue.set(state.cachedRoutes, name, data);
    }
  },

  actions: {
    FETCH_INCLUDE_ROUTES({ state, commit }, $route) {
      let result;
      let title;
      let includedRoute = [];
      let matched = false;
      const getRouteTitle = (routeName, _routes) => {
        if (!_routes) {
          matched = false;
          _routes = /vip/.test($route.path.split('/')[1]) ? mrRoutes : routes();
        }

        for (let i = 0; i < _routes.length; i++) {
          if (matched) {
            break;
          }

          const el = _routes[i];
          const { path, children = [], name, text } = el;

          if (path) {
            includedRoute.push({
              path,
              name,
              text,
              exact: true,
              disabled: el.disabled,
              params: $route.params,
              query: $route.query
            });
          }

          if (name === routeName) {
            result = text;
            matched = true;
            commit('setCurrentRoute', el);
            commit('setCacheRoute', { name, data: el });
            break;
          } else if (children.length) {
            getRouteTitle(routeName, children);
          } else if (!children.length && i + 1 === _routes.length) {
            includedRoute = [];
          } else {
            includedRoute.pop();
          }
        }
        return result;
      };

      const getIncludedRoute = (name) => {
        if (state.includedRoutes[name]) {
          const routes = state.includedRoutes[name];
          title = routes[routes.length - 1]?.text || '';
          return state.includedRoutes[name];
        }
        title = getRouteTitle(name);
        return includedRoute;
      };

      const cachedRoute = state.cachedRoutes[$route.name];
      if (cachedRoute) {
        commit('setCurrentRoute', cachedRoute);
      }

      commit('setIncludeRoute', {
        name: $route.name,
        routes: getIncludedRoute($route.name)
      });
      commit('setRouteTitle', title);
    },

    async FETCH_SITE_SETTINGS({ commit }) {
      commit('setSettingFetched', false);
      const Settings = await retrieve();

      if (!Settings.error) {
        commit('setSettingFile', Settings);
        commit('setSettingFetched', false);
      }
    }
  }
};
