import Vue from 'vue';
import VueI18n from 'vue-i18n';

import CHS from './lang/zh-CN.json'; // 存放簡體
import CHT from './lang/zh-TW.json'; // 存放繁體

// i18n

Vue.use(VueI18n);

let browserLanguage = window?.navigator?.language;

// 先塞入瀏覽器預設語言在 i18n
if (browserLanguage.toLowerCase() === 'zh-cn') {
  browserLanguage = 'zh-CN';
} else if (browserLanguage.toLowerCase() === 'zh-tw') {
  browserLanguage = 'zh-TW';
} else {
  browserLanguage = 'zh-CN';
}

// 存放語系 json
const messages = {
  'zh-CN': CHS,
  'zh-TW': CHT
};

export const i18n = new VueI18n({
  locale: browserLanguage, // set locale
  fallbackLocale: browserLanguage,
  messages // set locale messages
});
