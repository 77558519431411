import { api } from '.';

export function addRole(Role) {
  return api('/v1/roles/addRole', Role);
}

export function editRole(Role) {
  return api('/v1/roles/editRole', Role);
}

export function editRoleStatus(payload) {
  return api('/v1/roles/editRoleStatus', payload);
}

export function getRoles(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/roles/getRoles', payload);
}

export function getMenuActionPermissions(role_id) {
  return api('/v1/roles/getMenuActionPermissions', { role_id });
}

export function editMenuActionPermissions({ roleId, grants = {} }) {
  return api('/v1/roles/editMenuActionPermissions', {
    role_id: roleId,
    grants
  });
}
