<template>
  <div class="d-block d-sm-none">
    <v-menu offset-y top :nudge-bottom="-8">
      <template #activator="{ on }">
        <v-btn
          v-on="on"
          color="primary"
          fab
          small
          fixed
          class="function-fab-btn"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-if="!noInfo" @click="$emit('info')">
          <v-icon class="mr-1">mdi-information-outline</v-icon>
          <span class="text-no-wrap">資訊</span>
        </v-list-item>
        <v-list-item @click="$emit('refresh')">
          <v-icon class="mr-1">mdi-refresh</v-icon>
          <span class="text-no-wrap">刷新</span>
        </v-list-item>
        <v-list-item v-if="!noFilter" @click="$emit('open-filter')">
          <v-icon class="mr-1">mdi-filter</v-icon>
          <span class="text-no-wrap">篩選</span>
        </v-list-item>

        <slot></slot>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    noFilter: {
      type: Boolean,
      default: false
    },
    noInfo: {
      type: Boolean,
      default: false
    }
  }
};
</script>