import Vue from 'vue'
import Vuex from 'vuex'

import app from './app.js'
import auth from './auth.js'
import confirm from './confirm.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    confirm
  },
  state: {
  },
  mutations: {
  },
  actions: {
  }
})
