<template>
  <div v-if="!$vuetify.breakpoint.smAndDown">
    <vue-excel-xlsx
      ref="EXPORT"
      :data="data"
      :columns="columns"
      :file-name="fileName"
      :file-type="fileType"
      :sheet-name="sheetName"
    >
      <v-btn rounded color="Secondary100" depressed>匯出</v-btn>
    </vue-excel-xlsx>
  </div>
</template>

<script>
import clone from 'just-clone';
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Object,
      default: () => ({})
    },
    fileName: {
      type: String,
      default: ''
    },
    fileType: {
      type: String,
      default: 'xlsx'
    },
    sheetName: {
      type: String,
      default: ''
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      columns: [],
      data: [],
      join: {},
      dataFormat: {}
    };
  },

  mounted() {
    this.render();
  },

  methods: {
    render() {
      this.$set(this, 'columns', this.parseColumns());
      this.$set(this, 'data', this.parseData());
    },
    parseColumns() {
      return this.headers
        .filter(({ value }) => {
          return !this.exclude.includes(value);
        })
        .map(({ value, text, dataFormat, join }) => {
          if (join) this.join[value] = join;
          if (dataFormat) this.dataFormat[value] = dataFormat;
          const output = { label: text, field: value };
          return output;
        });
    },
    parseData() {
      const lableKeys = Object.keys(this.labels);
      const joinKeys = Object.keys(this.join);
      const dataFormats = Object.keys(this.dataFormat);
      let items = clone(this.items);

      if (lableKeys.length || joinKeys.length) {
        items = items.map((el) => {
          lableKeys.length &&
            lableKeys.forEach((k) => {
              k.split('.').reduce(
                (p, c) =>
                  (p[c] =
                    (this.labels[k].find(({ value }) => p[c] === value) || {})
                      .text || p[c]),
                el
              );
            });

          joinKeys.length &&
            joinKeys.forEach((j) => {
              j.split('.').reduce((p, c) => {
                const oV = p[c];
                const foV = this.dataFormat[j] ? this.dataFormat[j](oV) : oV;
                const jV = this.join[j]
                  .split('.')
                  .reduce((_p, _c) => _p[_c], el);
                const fjV = this.dataFormat[j] ? this.dataFormat[j](jV) : jV;

                p[c] = `${foV}\n/\n${fjV}`;
              }, el);
            });

          return el;
        });
      }
      if (dataFormats.length) {
        items = items.map((el) => {
          dataFormats.forEach((f) => {
            if (!joinKeys.includes(f)) {
              f.split('.').reduce((p, c) => {
                p[c] = this.dataFormat[f](p[c]);
              }, el);
            }
          });

          return el;
        });
      }

      return items;
    }
  },

  watch: {
    items: {
      deep: true,
      handler() {
        this.render();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>