const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const required = (v, text = '此為必填項目') => Array.isArray(v) ? !!v.length || text : !!v || text;
const requiredNum = (v, text) => typeof v === 'number' || required(v, text);
const email = (v, text = 'email 格式錯誤') => emailRegExp.test(v) || text;
const maxLength = (v, length, text) => `${v}` ? `${v}`.length <= length || text || `長度不可超過 ${length}` : true
const minLength = (v, length, text) => `${v}` ? `${v}`.length > length || text || `長度不可少於 ${length}` : true

export default {
  required,
  requiredNum,
  email,
  maxLength,
  minLength
}
