import { api } from '.';

export function checkCaptcha(payload) {
  return api('/v1/2fa/checkCaptcha', payload);
}

export function getCaptcha() {
  return api('/v1/2fa/getCaptcha');
}

export function checkSecret(account) {
  return api('/v1/2fa/checkSecret', { account });
}

export function resetSecret(id) {
  return api('/v1/2fa/resetSecret', { user_id: id });
}

export function resetSecretPw({ user_id, password }) {
  return api('/v1/2fa/resetSecretPw', { user_id, password });
}

export function resetOwnSecret() {
  return api('/v1/2fa/resetOwnSecret');
}

export function getSecret(payload) {
  return api('/v1/2fa/getSecret', payload);
}

export function makeSecret({ account, password }) {
  return api('/v1/2fa/makeSecret', { account, password });
}

export function enableOTP(payload) {
  return api('/v1/2fa/enableOTP', payload);
}

export function checkPassword(payload) {
  return api('/v1/2fa/checkPassword', payload);
}
