import Vue from 'vue';
import { getCurrentUser, renewToken } from '../api/session';
import {
  getCurrentUser as getCurrentMerchantUser,
  mrRenewToken
} from '../api/merchantCenter/session';
import jwtDecode from 'jwt-decode';
import { getMerchant } from '@/api/merchants';
import { getMerchant as mrGetMerchant } from '@/api/merchantCenter/merchants';
import { getMenuActionPermissions } from '@/api/roles';
import grants from '@/grants';
import { mergeDeep } from '@/plugins/megeDeep';

const isPayingProcess = /paying-process/g.test(location.pathname);

let refreshTicker = null;

export default {
  namespaced: true,
  state: () => ({
    logStatus: null,
    user: null,
    merchant: null,
    grants
  }),

  getters: {
    getLogStatus: (state) => state.logStatus,
    getUser: (state) => state.user,
    getIsMerchant: (state) => state.user && !state.user.role,
    getMerchant: (state) => state.merchant,
    getGrants: (state) => state.grants,
    getGrantValue: (state) => (grant) => {
      const grantSplit = grant.split('.');
      const name = grantSplit[0];
      const value = grantSplit[1];

      return state.grants[name][value];
    }
  },

  mutations: {
    setLogStatus(state, status) {
      state.logStatus = status;
    },
    setUser(state, user) {
      Vue.set(state, 'user', user);
    },
    setMerchant(state, merchant) {
      Vue.set(state, 'merchant', merchant);
    },
    setGrants(state, userGrants) {
      const mergedGrants = mergeDeep(grants, userGrants);
      Vue.set(state, 'grants', mergedGrants);
    }
  },

  actions: {
    async REFRESH_TOKEN({ dispatch, getters }) {
      if (isPayingProcess) return;
      const rt = localStorage.getItem('rt');
      const isMerchant = getters.getIsMerchant;
      const firedApi = isMerchant ? mrRenewToken : renewToken;
      if (rt) {
        const { token, error } = await firedApi({ refresh_token: rt });
        console.log('isError', error);

        if (!error) {
          dispatch('SET_TOKENS', { token /* refresh_token */ });
        }
      }
    },
    async CHECK_LOGIN_STATUS({ dispatch, commit }) {
      if (isPayingProcess) return false;
      const at = window.localStorage.getItem('at');
      if (at) {
        commit('setLogStatus', 'login');
        const decodedUser = jwtDecode(at);
        const isMr = !decodedUser.role;
        const user = isMr
          ? await getCurrentMerchantUser()
          : await getCurrentUser();
        if (!user.error) {
          isMr && window.localStorage.setItem('mrId', user.merchant_id);
          if (!isMr) {
            const Grants = await getMenuActionPermissions(user.role.id);

            if (!Grants.error) {
              commit('setGrants', Grants.grants);
            }
          }
          commit('setUser', user);
          refreshTicker = setInterval(() => {
            dispatch('REFRESH_TOKEN');
          }, 1000 * 60 * 60 * 1 - 1000);

          return isMr ? 'merchant' : 'user';
        } else {
          // dispatch('DO_LOGOUT');
          return false;
        }
      }
    },
    async FETCH_MERCHAT({ dispatch, commit }) {
      if (isPayingProcess) return;
      const mrId = window.localStorage.getItem('mrId');
      const at = window.localStorage.getItem('at');
      if (!at) return;
      const decodedUser = jwtDecode(at);
      const isMr = !decodedUser.role;

      if (mrId) {
        if (!isMr) {
          const Merchant = await getMerchant({ id: mrId });
          if (!Merchant.error) {
            commit('setMerchant', Merchant);
          }
        } else {
          const Merchant = await mrGetMerchant();
          if (!Merchant.error) {
            commit('setMerchant', Merchant);
          }
        }
      }

      if (!mrId) dispatch('CLEAR_MERCHANT');
    },
    CLEAR_MERCHANT({ commit }) {
      commit('setMerchant', null);
    },
    async FETCH_GRANTS({ state, commit }) {
      if (state.user && state.user.role) {
        const Res = await getMenuActionPermissions(state.user.role.id);

        if (state.user.role.slug === 'admin') {
          const allTrueGrant = { ...grants };
          Object.keys(allTrueGrant).forEach((k) => {
            Object.keys(allTrueGrant[k]).forEach(
              (_k) => allTrueGrant[k] && (allTrueGrant[k][_k] = true)
            );
          });
          commit('setGrants', allTrueGrant);

          return allTrueGrant;
        }
        if (Res && !Res.error) {
          commit('setGrants', Res.grants);
        }

        return Res.grants;
      }
    },
    DO_LOGOUT({ commit }) {
      refreshTicker && clearInterval(refreshTicker);
      refreshTicker = null;
      window.localStorage.removeItem('at');
      window.localStorage.removeItem('rt');
      window.localStorage.removeItem('mrId');
      commit('setLogStatus', null);
    },
    SET_TOKENS(store, { token, refresh_token }) {
      token && window.localStorage.setItem('at', token);
      refresh_token && window.localStorage.setItem('rt', refresh_token);
    }
  }
};
