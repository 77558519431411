<template>
  <v-app style="background-color: #ebe6e6">
    <Async :loading="loading">
      <template v-if="isPayingProcess">
        <router-view />
      </template>

      <template v-else-if="isLogin">
        <SideDrawer />
        <v-slide-x-transition>
          <v-btn
            rounded
            v-if="!$store.getters['app/getIsDrawerOpen']"
            @click="$store.commit('app/setIsDrawerOpen', true)"
            class="expand_btn"
            color="Primary"
            width="24"
            small
            dark
          >
            <v-icon size="18">mdi-forwardburger</v-icon>
          </v-btn>
        </v-slide-x-transition>
        <v-main>
          <div class="d-flex">
            <div class="full-width">
              <v-app-bar
                app
                class="app_bar_custom_shadow"
                height="52"
                color="Secondary800"
                dark
              >
                <span>{{ $store.getters['app/getRouteTitle'] }}</span>

                <template v-if="!$vuetify.breakpoint.smAndDown">
                  <SizeBox width="28" />
                  <v-divider vertical></v-divider>

                  <v-breadcrumbs :items="breadcrumbs" large>
                    <template #divider>
                      <v-icon>mdi-chevron-right</v-icon>
                    </template>
                  </v-breadcrumbs>
                </template>

                <v-spacer></v-spacer>

                <UserInfoMeun />
              </v-app-bar>

              <div class="mx-1 ml-sm-4">
                <router-view />
                <div style="height: var(--filter-padding)"></div>
              </div>
            </div>
          </div>
        </v-main>
      </template>
      <template v-else>
        <MrLogin v-if="isMr" />
        <Login v-else />
      </template>
    </Async>

    <ConfirmForm />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import SideDrawer from '@/components/layout-comps/dashboardDrawer/index.vue';
import Login from '@/page/Login.vue';
import MrLogin from '@/mrPage/Login.vue';
import UserInfoMeun from '@/components/UserInfoMeun';
import ConfirmForm from '@/components/ConfirmForm';
import { theme1, theme2 } from './plugins/color';

const isDev = process.env.NODE_ENV === 'development';
const isPayingProcess = /paying-process/g.test(location.pathname);

export default {
  name: 'App',

  components: {
    SideDrawer,
    Login,
    MrLogin,
    UserInfoMeun,
    ConfirmForm
  },

  data: () => ({
    isPayingProcess,
    loading: true,
    isDev
  }),

  computed: {
    ...mapGetters({
      getIncludedRoute: 'app/getIncludedRoute',
      tempBreadcrumbs: 'app/getTempBreadcrumbs',
      logStatus: 'auth/getLogStatus'
    }),
    isMr() {
      return /vip/.test(location.pathname.split('/')[1]);
    },
    isLogin() {
      return this.logStatus === 'login';
    },
    includedRoute: {
      immediate: true,
      get() {
        return this.getIncludedRoute(this.$route.name).filter(
          (el) => !!el.name
        );
      }
    },
    breadcrumbs() {
      return [...this.includedRoute, ...this.tempBreadcrumbs].map(
        (el, i, arr) => ({
          text: el.text,
          exact: true,
          disabled: !(i + 1 < arr.length),
          to: {
            name: el.name,
            params: el.params,
            query: el.query
          }
        })
      );
    }
  },

  watch: {
    $route: {
      handler(route) {
        this.$store.dispatch('app/FETCH_INCLUDE_ROUTES', route);

        if (/vip/.test(route.path.split('/')[1])) {
          this.$vuetify.theme.themes = theme2;
        } else {
          this.$vuetify.theme.themes = theme1;
        }
      }
    }
  },

  async created() {
    this.$store.dispatch('app/FETCH_INCLUDE_ROUTES', this.$route);

    const isMrRoute = /vip/.test(location.pathname.split('/')[1]);

    const isLogin = this.$store.getters['auth/getLogStatus'] === 'login';
    const isMerchant = this.$store.getters['auth/getIsMerchant'];

    if (isMrRoute) {
      if (isLogin && !isMerchant && !localStorage.getItem('mrId')) {
        location.href = location.origin;
      }
    }

    // document.querySelector('head > title').innerText = isMrRoute
    //   ? '商戶平台'
    //   : '金流商平台';

    document.querySelector('link[rel=icon]').href = `/${
      isMrRoute ? 'mr' : ''
    }favicon.ico/`;

    let root = document.documentElement;

    const settingTheme = isMrRoute ? theme2 : theme1;
    Object.keys(settingTheme.light).forEach((key) => {
      root.style.setProperty(`--${key}`, settingTheme.light[key]);
    });

    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
.expand_btn {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  min-width: auto !important;
}

.app_bar_custom_shadow {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1) !important;
}

::v-deep {
  .v-breadcrumbs__item {
    position: relative;
    color: white !important;
    &.v-breadcrumbs__item--disabled {
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 90%;
        top: 120%;
        left: 50%;
        transform: translateX(-50%);
        height: 6px;
        border-radius: 99px;
        background-color: $Error;
      }
    }
  }
}
</style>
