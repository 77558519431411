import store from '@/store';

/**
 * @interface Route
 *
 * @param { String } icon - mdi icon
 * @param { String } customIcon - custom svg icons added at '@/icons'
 * @param { String } name - route name
 * @param { String } path - path for route
 * @param { Boolean } hidden - visibility in drawer by style display
 * @param { Boolean } dontExist - visibility in drawer by VNode life cycle
 * @param { Array<Route> } children - children route
 * @param { Boolean } disabled - won't push route when true
 * @param { Boolean } expandable - expandable
 * @param { String } grant - visibility by grant
 */

const originRoutes = [
  {
    icon: '',
    text: '付款資訊',
    name: 'paying-process',
    path: '/paying-process',
    component: () => import('../page/paying-process.vue'),
    hidden: true,
    grant: true
  },
  {
    icon: '',
    text: '',
    name: '',
    path: '/',
    component: () => import('../page/index.vue'),
    redirect: '/overview',
    hidden: true,
    grant: true,
    children: [
      {
        icon: 'mdi-home-variant-outline',
        text: '首頁',
        name: '首頁',
        path: '/overview',
        grant: 'home.read',
        component: () => import('../page/overview.vue')
      },

      {
        icon: 'mdi-account-details-outline',
        text: '商戶管理',
        name: '商戶管理',
        expandable: true,
        path: '/merchant',
        redirect: '/merchant/list',
        grant: ['merchant_list.read', 'merchant_type_list.read'],
        component: () => import('../page/merchant/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            text: '商戶列表',
            name: '商戶列表',
            path: '/merchant/list',
            grant: 'merchant_list.read',
            component: () => import('../page/merchant/list.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '商戶類別',
            name: '商戶類別',
            path: '/merchant/category',
            grant: 'merchant_type_list.read',
            component: () => import('../page/merchant/category.vue')
          }
        ]
      },

      {
        icon: 'mdi-format-list-bulleted-triangle',
        text: '代收管理',
        name: '代收管理',
        expandable: true,
        path: '/collect',
        redirect: '/collect/order',
        grant: 'collect_order_list.read',
        component: () => import('../page/collect/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            text: '代收訂單',
            name: '代收訂單',
            path: '/collect/order',
            grant: 'collect_order_list.read',
            component: () => import('../page/collect/order.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '未認領訂單',
            name: '未認領訂單',
            path: '/collect/not-processed',
            grant: 'not_process_collect_order_list.read',
            component: () => import('../page/collect/not-processed.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '代收黑名單',
            name: '代收黑名單',
            path: '/collect/black-list',
            grant: 'black_list_collect_order_list.read',
            component: () => import('../page/collect/black-list.vue')
          }
        ]
      },

      {
        icon: 'mdi-format-list-bulleted-type',
        text: '代付管理',
        name: '代付管理',
        expandable: true,
        path: '/pay',
        redirect: '/pay/merchant-list',
        grant: ['pay_order_list.read', 'api_pay_order_list.read'],
        component: () => import('../page/pay/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            text: '商戶代付列表',
            name: '商戶代付列表',
            path: '/pay/merchant-list',
            grant: 'pay_order_list.read',
            component: () => import('../page/pay/merchant-list.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: 'API 代付列表',
            name: 'API 代付列表',
            path: '/pay/api-list',
            grant: 'api_pay_order_list.read',
            component: () => import('../page/pay/api-list.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '代付黑名單',
            name: '代付黑名單',
            path: '/pay/black-list',
            grant: 'black_list_pay_order_list.read',
            component: () => import('../page/pay/black-list.vue')
          }
        ]
      },

      {
        icon: 'mdi-currency-usd',
        text: '資金管理',
        name: '資金管理',
        expandable: true,
        path: '/funding',
        redirect: '/funding/history',
        grant: [
          'capital_log_list.read',
          'collect_orders_leaderboard.read',
          'frozen_log_list.read'
        ],
        component: () => import('../page/funding/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            text: '帳變紀錄',
            name: '帳變紀錄',
            path: '/funding/history',
            grant: 'capital_log_list.read',
            component: () => import('../page/funding/history.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '儲值排行',
            name: '儲值排行',
            path: '/funding/ranking',
            grant: 'collect_orders_leaderboard.read',
            component: () => import('../page/funding/ranking.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '凍結紀錄',
            name: '凍結紀錄',
            path: '/funding/frozen',
            grant: 'frozen_log_list.read',
            component: () => import('../page/funding/frozen.vue')
          }
        ]
      },

      {
        icon: 'mdi-tune-vertical-variant',
        text: '風控管理',
        name: '風控管理',
        expandable: true,
        path: '/rule',
        redirect: '/rule/collect',
        grant: ['collect_rule.read', 'pay_rule.read'],
        component: () => import('../page/rule/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            text: '代收風控',
            name: '代收風控',
            path: '/rule/collect',
            grant: 'collect_rule.read',
            component: () => import('../page/rule/collect.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '代付風控',
            name: '代付風控',
            path: '/rule/pay',
            grant: 'pay_rule.read',
            component: () => import('../page/rule/pay.vue')
          }
        ]
      },

      {
        icon: 'mdi-transit-detour',
        text: '通道管理',
        name: '通道管理',
        expandable: true,
        path: '/channel',
        redirect: '/channel/list',
        grant: [
          'collect_channel_settings.read',
          'cards_list.read'
          // 'platform_billing.read',
          // 'pay_channel_settings.read',
        ],
        component: () => import('../page/channel/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            text: '交易通道設定',
            name: '交易通道設定',
            path: '/channel/list',
            grant: 'collect_channel_settings.read',
            component: () => import('../page/channel/list.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '子帳戶列表',
            name: '子帳戶列表',
            path: '/channel/accounts',
            grant: 'cards_list.read',
            component: () => import('../page/channel/accounts.vue')
          }
          // {
          //   icon: 'mdi-circle-outline',
          //   text: '平台使用計費',
          //   name: '平台使用計費',
          //   path: '/channel/pricing',
          //   grant: 'platform_billing.read',
          //   component: () => import('../page/channel/pricing.vue')
          // },
          // {
          //   icon: 'mdi-circle-outline',
          //   text: '轉單通道設定',
          //   name: '轉單通道設定',
          //   path: '/channel/transfer',
          //   grant: 'pay_channel_settings.read',
          //   component: () => import('../page/channel/transfer.vue')
          // }
        ]
      },

      {
        icon: 'mdi-chart-line',
        text: '數據報表',
        name: '數據報表',
        expandable: true,
        path: '/statistics',
        redirect: '/statistics/sum',
        grant: [
          'platform_order_reports.read',
          'merchant_accounting_reports.read',
          'agent_accounting_reports.read',
          'channel_accounting_reports.read'
        ],
        component: () => import('../page/statistics/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            text: '平台交易統計',
            name: '平台交易統計',
            path: '/statistics/sum',
            grant: 'platform_order_reports.read',
            component: () => import('../page/statistics/sum.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '商務帳戶報表',
            name: '商務帳戶報表',
            path: '/statistics/merchant',
            grant: 'merchant_accounting_reports.read',
            component: () => import('../page/statistics/merchant.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '代理帳戶報表',
            name: '代理帳戶報表',
            path: '/statistics/proxy',
            grant: 'agent_accounting_reports.read',
            component: () => import('../page/statistics/proxy.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '通道帳務統計',
            name: '通道帳務統計',
            path: '/statistics/channel-type',
            grant: 'channel_accounting_reports.read',
            showPath: true,
            component: () => import('../page/statistics/channel.vue'),
            children: [
              {
                icon: 'mdi-circle-outline',
                text: '子通道清單',
                name: '子通道清單',
                path: '/statistics/channel-type/:channel_type_id',
                grant: 'channel_accounting_reports.read',
                hidden: true,
                component: () => import('../page/statistics/_channel_type.vue'),
                children: [
                  {
                    icon: 'mdi-circle-outline',
                    text: '子帳號清單',
                    name: '子帳號清單',
                    path: '/statistics/channel-type/:channel_type_id/:channel_id',
                    grant: 'channel_accounting_reports.read',
                    hidden: true,
                    component: () =>
                      import('../page/statistics/_sub_account.vue')
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },

  {
    icon: '',
    text: '系統',
    name: '系統',
    path: '/system',
    redirect: '/system/system-log',
    grant: true,
    component: () => import('../page/system.vue'),
    children: [
      {
        icon: 'mdi-book-open-outline',
        text: '系統日誌',
        name: '系統日誌',
        path: '/system/system-log',
        redirect: '/system/system-log/sms',
        expandable: true,
        grant: [
          'sms_list.read',
          'signin_log_list.read'
          // 'operation_log_list.read',
          // 'backup_logs.read',
        ],
        component: () => import('../page/system-log/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            text: '簡訊紀錄',
            name: '簡訊紀錄',
            path: '/system/system-log/sms',
            grant: 'sms_list.read',
            component: () => import('../page/system-log/sms.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '登入日誌',
            name: '登入日誌',
            path: '/system/system-log/logins',
            grant: 'signin_log_list.read',
            component: () => import('../page/system-log/logins.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '操作紀錄',
            name: '操作紀錄',
            path: '/system/system-log/operation',
            grant: 'operation_log_list.read',
            component: () => import('../page/system-log/operation.vue')
          }
          // ,{
          //   icon: 'mdi-circle-outline',
          //   text: '日誌備份',
          //   name: '日誌備份',
          //   path: '/system/system-log/backups',
          //   grant: 'backup_logs.read',
          //   component: () => import('../page/system-log/backups.vue')
          // }
        ]
      },
      {
        icon: 'mdi-circle',
        text: '系統權限',
        name: '系統權限',
        path: '/system/system-management',
        redirect: 'system-management/managers',
        expandable: true,
        grant: ['user_list.read', 'role_list.read', 'menu_settings.read'],
        component: () => import('../page/system-management/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            text: '管理員名單',
            name: '管理員名單',
            path: '/system/system-management/managers',
            grant: 'user_list.read',
            component: () => import('../page/system-management/managers.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '角色設定',
            name: '角色設定',
            path: '/system/system-management/roles',
            grant: 'role_list.read',
            component: () => import('../page/system-management/roles.vue')
          }
          // {
          //   icon: 'mdi-circle-outline',
          //   text: '角色設定',
          //   name: '角色設定',
          //   path: '/system/system-management/roles',
          //   grant: 'menu_settings.read',
          //   component: () => import('../page/system-management/roles.vue')
          // },
        ]
      },
      {
        icon: 'mdi-circle',
        text: '系統管理',
        name: '系統管理',
        path: '/system/system-config',
        redirect: 'system-config/app-config',
        expandable: true,
        grant: [
          'platform_settings.read',
          'password_settings.read',
          'clear_cache.read',
          'system_version.read'
        ],
        component: () => import('../page/system-config/index.vue'),
        children: [
          {
            icon: 'mdi-circle-outline',
            text: '網站設定',
            name: '網站設定',
            path: '/system/system-config/app-config',
            grant: 'platform_settings.read',
            component: () => import('../page/system-config/app-config.vue')
          },
          {
            icon: 'mdi-circle-outline',
            text: '密碼修改',
            name: '密碼修改',
            path: '/system/system-config/password-change',
            grant: 'password_settings.read',
            component: () => import('../page/system-config/password-change.vue')
          }
          // {
          //   icon: 'mdi-circle-outline',
          //   text: '清理暫存',
          //   name: '清理暫存',
          //   path: '/system/system-config/cache-clean',
          //   grant: 'clear_cache.read',
          //   component: () => import('../page/system-config/cache-clean.vue')
          // },
          // {
          //   icon: 'mdi-circle-outline',
          //   text: '系統版本',
          //   name: '系統版本',
          //   path: '/system/system-config/version',
          //   grant: 'system_version.read',
          //   component: () => import('../page/system-config/version.vue')
          // }
        ]
      }
    ]
  }
];

export const filterByGrant = () => {
  const grants = store.getters['auth/getGrants'];

  const getGrantValue = (grantName) => {
    if (Array.isArray(grantName)) {
      const grantNames = grantName;
      return grantNames.some((_grantName) => {
        const grantSplit = _grantName.split('.');
        const name = grantSplit[0];
        const read = grantSplit[1];

        return grants[name][read];
      });
    } else if (typeof grantName === 'string') {
      const grantSplit = grantName.split('.');
      const name = grantSplit[0];
      const read = grantSplit[1];

      return grants[name][read];
    } else if (grantName === true) {
      return true;
    }
  };

  const recuresiveMap = (routes = originRoutes) => {
    return routes
      .map((_route) => {
        if (getGrantValue(_route.grant)) {
          if (_route.children && _route.children.length) {
            _route.children = recuresiveMap(_route.children);
          }

          return _route;
        }
      })
      .filter((v) => !!v);
  };

  return recuresiveMap();
};

export const routes = () =>
  process.env.VUE_APP_SHOW_GLOBAL_COMPONENT
    ? [
        ...filterByGrant(),
        {
          icon: 'mdi-scent',
          text: 'GlobalComponents',
          name: 'GlobalComponents',
          path: '/dev/global-components',
          component: () => import('../components/Globals/index.vue')
        }
      ]
    : filterByGrant();
