export default {
  labels: [],
  options: {},
  headers: [],

  isLoading: true,
  showingForm: null,
  types: [],
  result: {
    header_info: {},
    list: [],
    total: 0,
    page: 1
  },
  isInfoOpen: false,
  isFilterOpen: false,
  filterOptions: [],
  query: {},
  filter: {},
  condition: {
    page: 1,
    limit: 20
  },

  passData: null
};
